/*! Teaser */

.Teaser {
  display: block;
  margin-bottom: 30px;
  color: $color-black;

  @media($mq-from-tablet) {
    display: flex;
    margin-bottom: 60px;

    & > * {
      flex: 0 1 auto;
      overflow: hidden;
      min-height: 388px;
    }
  }
  @media($mq-desktop) {
    & > * { width: 50%; }
  }
}

.Teaser-img {
  background-color: $color-gray;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @media ($mq-phone) {
    img {
      max-height: 250px;
      object-fit: cover;
    }
  }
  @media($mq-tablet) {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    width: 50%;
  }
}

.Teaser-content {
  padding: 20px 20px;
  background-color: #fff;

  @media($mq-tablet) {
    flex: 1 0 auto;
    width: 50%;
  }

  @media($mq-desktop) {
    padding: 55px 75px;
  }
}

.Teaser-title {
  @extend .square, .square--center;
  margin-bottom: 40px;
  font-size: .8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: $color-darkBlue;

  // Color Variant on TeaserList --  Need important
  .Teaser--1 & { @extend .square--blue; }
  .Teaser--2 & { @extend .square--green; }
  .Teaser--3 & { @extend .square--yellow; }
  .Teaser--4 & { @extend .square--pink; }

  // Animatin on desktop
  @media($mq-desktop) {
    &::after {
      transition: width 600ms cubic-bezier(.47,1.76,.52,.53), transform 100ms linear;
    }
    a.Teaser:hover &:after,
    a.Teaser:focus &:after {
      width: 100%;
      transform: scaleY(.25);
    }
  }
}

.Teaser-text:not(:last-child) {
  margin-bottom: 30px;

  @media ($mq-from-tablet) {
    margin-bottom: 80px;
  }

  // We can have BR elements because we use nl2br
  // If we have several in a row, only render the first one
  br + br {
    display: none;
  }
}

.Teaser-btn {
  text-align: center;

  .Btn { min-width: inherit; }
}

// Variant: bigger teaser

.Teaser--big {
  @media($mq-tablet) {
    .Teaser-img { min-height: 450px; }
  }
  @media($mq-desktop) {
    .CatHead + & { margin-top: -122px; }

    & > * { min-height: 626px; }

    .Teaser-img { order: 2; }

    .Teaser-content {
      order: 1;
      padding: 75px;
    }

    .Teaser-title { margin-bottom: 80px; }
  }
}