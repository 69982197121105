/*! FactBox */

div.factbox-design {
  float: right;
  display: block;
  border: 1px solid $color-lightGray;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #efefef;

  h2 {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
  }

  @media ($mq-desktop) {
    width: 50%;
  }
}

.factbox-content {
  overflow: hidden;
}
