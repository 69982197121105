/*! Press */
// Press releases

.Press {
  margin-top: 30px;
  margin-bottom: 60px;
  font-size: .9rem;

  @media($mq-from-tablet) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.Press-title {
  @extend .square;
  margin-bottom: 30px;
  font-family: $font-title;
  font-size: .8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-darkBlue;

  .Press:nth-of-type(even) & { @extend .square--blue; }
  .Press:nth-of-type(odd) & { @extend .square--pink; }
}

.Press-item {
  display: inline-block;
  width: 100%;
  padding: 15px 20px;
  font-weight: 600;
  text-decoration: none;
  color: $color-black;

  &:hover, &:focus { text-decoration: underline; }

  &:nth-child(odd) { background-color: $color-lighterGray; }
}

.Press-item--Text {
  @extend .Press-item;
  &:hover, &:focus {text-decoration: none;}
}

.Press-date {
  font-weight: 400;
  font-style: italic;
}

.Press-more {
  margin-top: 25px;
  text-align: right;
}

.Press:nth-of-type(even) .Press-more .icon--btn {
  stroke: $color-lightBlue;
}

.Press:nth-of-type(odd) .Press-more .icon--btn {
  stroke: $color-pink;
}

