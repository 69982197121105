/*! Preview */

.Preview {
  margin-top: 60px;
  margin-bottom: 60px;
  padding-left: 45px;
  padding-right: 45px;

  & + & {
    border-top: 1px solid $color-gray;
    padding-top: 60px;
  }

  @media($mq-upto-tablet) {
    display: none;
  }
}

.Preview-title {
  @extend .square;
  margin-bottom: 30px;
  font-family: $font-title;
  font-size: .8rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-darkBlue;

  .Preview:nth-of-type(even) & { @extend .square--blue; }
  .Preview:nth-of-type(odd) & { @extend .square--pink; }
}

.Preview-item {
  display: block;
  margin-bottom: 30px;
  font-weight: 600;
  text-decoration: none;
  color: $color-black;

  span {
    display: block;
    margin-top: 5px;
  }

  &:last-of-type { margin-bottom: 0;}

  &:hover, &:focus { text-decoration: underline; }
}

.Preview-more {
  font-family: $font-title;
  font-weight: 700;
  color: $color-black;

  &:hover, &:focus { text-decoration: underline; }
}