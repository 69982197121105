/*! Override lightbox styles */

.lb-outerContainer {
  height:auto !important;
  width:auto !important ;
  display:inline-block !important;
}

.lb-container {
  display: inline-block;
}

.lb-dataContainer {
  width:600px !important;
  margin-top: 20px;

  @media($mq-phone) {
    width:250px !important;
  }
}

.lb-image {
  @media($mq-upto-tablet) {
    max-width: 300px !important;
  }
}