/*! FAQ component */

.FAQ {
  margin-top: 2rem;
}

.FAQ-title {
  margin-bottom: 1rem;
}

// Vote block (inside Accordion content)
.FAQ-vote {
  margin-top: 25px;
  border-top: dashed 1px #ddd;
  padding-top: 15px;
  color: #555;
}

.FAQ-voteButton {
  display: inline-block;
  padding: 3px 6px;
  border: none;
  color: inherit;
  background-color: transparent;
}

.FAQ-voteButton[aria-pressed="true"] {
  color: white;
  background-color: #1e3f8a;
}

.FAQ-voteMessage {
  margin-top: 0.25em;
  font-size: 85%;
}

.FAQ-more {
  margin-top: 25px;
  text-align: right;
  text-transform: uppercase;
}
