/*! Summary Component */

// Main container
// Only styled on desktop

.Summary {
  @media ($mq-upto-tablet) {
    // Disable the height set in JS, if any
    height: auto !important;
  }

  // We always keep the Summary container in the right column
  // We will use JavaScript to give it a fixed height when stickified
  @media ($mq-desktop) {
    // eat into the hero content
    margin-top: -101px;
    min-height: 200px;
    // Override the fixed height (set in JS) when not sticky
    &[data-sticky="false"] {
      height: auto !important;
    }

    // Add a bit of space in the right column if it only contains a summary
    &:last-child {
      margin-bottom: 60px;
    }
  }
}

// Mixin with fixed styles for the summary open button and box

@mixin summary-sticky-position-desktop {
  position: fixed;
  z-index: $zindex-summary;
  bottom: 10px;
  right: 20px;
  min-width: 280px;
  // rough approximation of the width taken by the content of the right column
  width: 29.5vw;
  @media (min-width: 1220px) {
    right: calc(50vw - 600px);
    width: 368px;
  }
}

// Open button
// Mobile: shown on bottom of screen if data-state="inactive"
// Desktop: shown on bottom right if data-state="inactive" and data-sticky="true"

.Summary-open {
  display: block;
  margin: 0 auto;
  width: 280px;
  min-height: 50px;
  padding: 10px;
  font-family: $font-title;
  font-weight: 700;
  font-size: .8rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: $color-black;
  background-color: $color-lightBlue;
  cursor: pointer;

  .icon {
    vertical-align: middle;
    margin-left: 12px;
    font-size: 20px;
  }

  @media ($mq-upto-tablet) {
    .Summary[data-state="active"] & {
      display: none;
    }
    .Summary[data-state="inactive"] & {
      position: fixed;
      z-index: $zindex-summary;
      left: calc(50% - 140px);
      bottom: 10px;
    }
  }

  @media ($mq-desktop) {
    @include summary-sticky-position-desktop;
    display: none;
    min-height: 60px;
    padding: 15px;

    .icon {
      margin-left: 15px;
      font-size: 30px;
    }

    .Summary[data-state="inactive"][data-sticky="true"] & {
      display: block;
    }
  }
}

// Content box
// Contains everything except the "Open" button

.Summary-box {
  @media ($mq-upto-tablet) {
    display: none;
    position: fixed;
    z-index: $zindex-summary-high;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    //transform: translateX(100%);
    //transition: transform 250ms;

    .Summary[data-state="active"] & {
      display: block;
      //transform: translateX(0);
      animation: slide-from-right 1000ms both;
    }
  }

  @media ($mq-desktop) {
    display: block;
    border: solid 1px $color-gray;
    background-color: #fff;

    .Summary[data-sticky="true"] & {
      @include summary-sticky-position-desktop;
      display: none;
      opacity: 0;
      transform: translateY(100%);
    }

    .Summary[data-sticky="true"][data-state="active"] & {
      display: block;
      opacity: 1;
      transform: none;
      animation: summary-fade-in-desktop 350ms;
    }

    @keyframes summary-fade-in-desktop {
      from {
        opacity: 0.2;
        transform: translateY(90%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

// Header with tile and close button

.Summary-head {
  position: relative;

  @media ($mq-upto-tablet) {
    height: 80px;
  }

  @media ($mq-desktop) {
    min-height: 80px;
    .Summary[data-sticky="false"] & {
      min-height: 100px;
    }
  }
}

// Title

.Summary-title {
  @media ($mq-upto-tablet) {
    display: block;
    padding: 30px;
    font-size: .8rem;
    line-height: 1rem;
    text-transform: uppercase;
    font-family: $font-title;
  }

  @media ($mq-desktop) {
    display: block;
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 0;
    padding-top: 10px;
    font-family: $font-title;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    &::after {
      content: "";
      display: block;
      width: 40px;
      height: 14px;
      margin: 6px auto 0;
      background-color: $color-lightBlue;
    }
  }
}

// Close button

.Summary-close {
  padding: 10px;
  cursor: pointer;

  .icon {
    font-size: 20px;
    vertical-align: middle;
    color: $color-black;
  }

  @media ($mq-upto-tablet) {
    position: absolute;
    z-index: 10;
    top: calc(50% - 20px);
    right: 10px;
    opacity: 0;
    transform: translateX(100px);

    .Summary[data-state="active"] & {
      transition: transform 250ms 200ms ease-out, opacity 250ms 200ms linear;
      opacity: 1;
      transform: translateX(0);
    }
  }

  @media ($mq-desktop) {
    position: absolute;
    top: 10px;
    right: 10px;

    .Summary[data-sticky="false"] & {
      display: none;
    }
  }
}

// Main content
// Can be scrolled on mobile and desktop

.Summary-content {
  @media ($mq-upto-tablet) {
    position: fixed;
    z-index: 5;
    top: $header-mobile-height;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;

    .Summary[data-state="active"] & {
      transform: translateX(0);
    }
  }

  @media ($mq-desktop) {
    .Summary[data-sticky="true"] & {
      // header is 90px, Summary-head is also roughly 90px
      max-height: calc(100vh - 220px);
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.Summary-list {
  padding: 15px 0 25px;

  @media ($mq-desktop) {
    padding: 30px 0;
  }

  a {
    color: $color-black;
    text-decoration: none;
  }
  a:hover, a:focus {
    text-decoration: underline;
  }
}

.Summary-item {
  & > a {
    position: relative;
    display: block;
    padding: 12px 30px;
    font-family: $font-title;
    font-size: .8rem;
    line-height: 1.25;
  }
  &--active > a {
    font-weight: 700;
  }
  &--border > a::after {
    content: "";
    position: absolute;
    top: 11px;
    left: 0;
    margin-top: 0;
    width: 12px;
    height: 20px;
    background-color: $color-black;
    //background-color: $color-lightBlue;
  }

  @media ($mq-desktop) {
    & > a {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 1rem;
    }
    &--border > a::after {
      top: 12px;
      height: 22px;
    }
  }

  @media ($mq-desktop-large) {
    & > a {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}

.Summary-subList {
  margin: 0 20px 10px;
  @media ($mq-desktop) {
    margin: 0 20px 20px;
  }
  @media ($mq-desktop-large) {
    margin-left: 30px;
    margin-right: 30px;
  }
  // Don't add extra space if it's empty
  // (e.g. not populated by JS because there are no in-page titles to link to)
  &:empty {
    display: none !important;
  }
}

.Summary-subItem > a {
  display: block;
  padding: 5px 10px;
  line-height: 1.4;
}
