/*! Sitemap */

.Sitemap {
  padding-top: 70px;
  padding-bottom: 70px;

  @media($mq-phone) {
    display: none;
  }
}

.Sitemap-cat {
  @media($mq-tablet) {
    margin-bottom: 30px;
  }
}

.Sitemap-title {
  @extend .square;
  margin-bottom: 25px;
  font-family: $font-title;
  font-size: .7rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-darkBlue;

  .Sitemap-cat:nth-child(1) & { @extend .square--green; }
  .Sitemap-cat:nth-child(2) & { @extend .square--pink; }
  .Sitemap-cat:nth-child(3) & { @extend .square--blue; }
  .Sitemap-cat:nth-child(4) & { @extend .square--yellow; }
}

.Sitemap-list a {
  display: block;
  padding-top: 5px;
  padding-bottom: 5px;
  color: $color-black;

  &:hover, &:focus { text-decoration: underline; }
}

.Sub-list {
  margin-left: 20px;
}

.Sub-list a {
  font-size: .7rem;
  display: block;
}