/*! Actions component */
// Homepage block

.Action {
  background-color: $color-lighterGray;
}

// ---------------------
// Image
// ---------------------

.Action-img {
  margin-bottom: 40px;

  @media($mq-phone) {
    display: none;
  }
}


// ---------------------
// Square
// ---------------------

.Action-square {
  @extend .square, .square--center, .square--full;
}

.Action {
  &:first-child .Action-square {
    @extend .square--yellow;
  }
  &:nth-child(2n) .Action-square {
    @extend .square--green;
  }
  &:nth-child(3n) .Action-square {
    @extend .square--pink;
  }
}

// ---------------------
// Content
// ---------------------

.Action-content {
  margin-bottom: 50px;

  ul li {
    line-height: 2.1;
    a {
      color: $color-black;
      font-weight: 600;
    }
  }

  @media($mq-upto-tablet) {
    margin-bottom: 40px;
  }
}

.Action-title {
  margin-bottom: 30px;
  margin-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-darkBlue;
}

.Action-btn {
  text-align: center;
  margin: 80px auto;

  .Btn { min-width: inherit; }
}