/*! Paginator */

.Paginator,
.pagination {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  color: $color-black;
  background-color: $color-lighterGray;

  @media($mq-from-tablet) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}


.Paginator li,
.pagination li {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 14px;
  margin-right: 14px;

  &.Paginator-firstBtn,
  &.Paginator-lastBtn,
  &.Paginator-current,
  &.Paginator-previousBtn,
  &.Paginator-nextBtn,
  &.active,
  &.prev,
  &.next {
    font-weight: bold;
  }

  &.Paginator-current,
  &.active {
    background-color: $color-green;
    margin-left: 0;
    margin-right: 0;
    padding-left: 14px;
    padding-right: 14px;
    line-height: 1;
  }

  &.Paginator-firstBtn,
  &.Paginator-previousBtn,
  &.prev {
    margin-right: 40px;
    margin-left: 0;
  }

  &.Paginator-lastBtn,
  &.Paginator-nextBtn,
  &.next {
    margin-left: 40px;
    margin-right: 0;
  }
}
@media($mq-phone) {
  .Paginator li,
  .pagination li {
    &.Paginator-previousBtn,
    &.Paginator-nextBtn {
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &.Paginator-previousBtn { margin-right: 0; }
    &.Paginator-nextBtn { margin-left: 0; }
  }
}

.Paginator a,
.pagination a {
  color: $color-black;
}