/*! LastPress */
// Homepage block

.LastPress {
  padding-top: 20px;
  padding-bottom: 20px;

  @media($mq-phone) {
    border-bottom: 1px solid $color-gray;
  }

  @media($mq-from-tablet) {
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 35px 30px;
  }
}

// ---------------------
// title
// ---------------------

.LastPress-title {
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-darkBlue;

  @extend .square;
}

.LastPress {
  &:first-child .LastPress-title {
    @extend .square--blue;
  }
  &:nth-child(2n) .LastPress-title {
    @extend .square--green;
  }
  &:nth-child(3n) .LastPress-title {
    @extend .square--pink;
  }
}


// ---------------------
// Content
// ---------------------

.LastPress-content {
  display: block;
  margin-bottom: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5;
  color: $color-black;

  &:hover { text-decoration: underline; }

  @media($mq-from-tablet) {
    max-width: 240px;
  }
}


// ---------------------
// Date
// ---------------------

.LastPress-date {
  display: block;
  margin-top: 10px;
  font-family: "Merriweather Sans";
  font-size: 0.7rem;
  font-weight: normal;
}


// ---------------------
// Button
// ---------------------

.LastPress-btn {
  display: block;
  text-align: right;
  font-weight: 600;
  color: $color-darkBlack;

  &:hover { text-decoration: underline; }
  
  @media($mq-desktop) {
    text-align: left;
  }
}