/*! Header */


// ---------------------
// Main Containers
// ---------------------

@keyframes header-slide-down {
  from { transform: translateY(-100%) }
  to { transform: translateY(0) }
}

.Header {
  min-width: 300px;
  color: $color-darkBlue;
  background-color: $color-white;

  @media ($mq-upto-tablet) {
    position: fixed;
    z-index: $zindex-header;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.4);
  }

  @media ($mq-desktop) {
    position: relative;
    z-index: $zindex-header;
    height: 140px;

    &[data-sticky="true"] {
      position: fixed;
      z-index: $zindex-header;
      top: 0;
      left: 0;
      right: 0;
      height: 90px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,.4);
      animation: header-slide-down ease-out 200ms;
    }
    &[data-sticky="true"] + main {
      margin-top: 140px;
    }
  }
}

.Header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($mq-upto-tablet) {
    height: $header-mobile-height;
  }
  @media ($mq-desktop) {
    height: 140px;
    position: relative;
    padding-top: 5px;
    padding-bottom: 5px;

    .Header[data-sticky="true"] & {
      height: 90px;
    }
  }
}

// ---------------------
// Logo
// ---------------------

.Header-logo {
  display: inline-block;
  vertical-align: middle;
  margin-left: -16px;
  padding: 10px;

  .icon {
    display: block;
    width: 125px;
    height: 50px;
    color: $color-darkBlue;
  }

  @media ($mq-desktop) {
    margin-left: -10px;
    .icon {
      width: 250px;
      height: 100px;
    }

    .Header[data-sticky="true"] & {
      margin-right: 80px; // maintain centering of the middle links
      .icon {
        width: 160px;
        height: 70px;
      }
    }
  }
}
