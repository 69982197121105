/*! NwsLttr component */
// Featuring Georges Perec


// ---------------------
// Main
// ---------------------

.NwsLttr { font-weight: 600; }


// ---------------------
// Title
// ---------------------

.NwsLttr-title {
  display: block;
  margin-bottom: 5px;
}


// ---------------------
// label
// ---------------------

.NwsLttr-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $color-black;

  .icon {
    width: 21px;
    height: 15px;
    margin-left: 20px;
  }

  // templating helpers generate a div around the input
  div {
    flex: 1 1 auto;
  }

  input {
    width: 100%;
    height: 50px;
    padding: 14px 20px 13px 20px;
    border: none;
    font-family: $font-text;
    font-weight: 600;
    color: $color-black;
  }

  button {
    width: 60px;
    height: 50px;
    font-size: .7rem;
    cursor: pointer;

    &:hover, &:focus { text-decoration: underline; }
  }

  @media($mq-phone) {
    justify-content: flex-start;

    & > div { width: calc(100% - 90px); }
    input { padding-left: 20px; }
  }

  @media($mq-from-tablet) {
    max-width: 400px;
  }
}
.NwsLttr-input input::-webkit-input-placeholder { color: $color-black; }/* Chrome/Opera/Safari */
.NwsLttr-input input::-moz-placeholder { color: $color-black; }/* Firefox 19+ */
.NwsLttr-input input:-ms-input-placeholder { color: $color-black; }/* IE 10+ */