/*! Share */

.Share {
  margin-bottom: 20px;
  margin-top: 20px;

  @media($mq-from-tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Share-item {
  font-size: .7rem;

  a {
    color: $color-black;
    &:hover, &:focus { text-decoration: underline; }
  }

  .icon--mail {
    vertical-align: -3px;
    width: 27px;
    height: 19px;
    margin-right: 5px;
  }
  .icon--printer {
    vertical-align: -9px;
    width: 30px;
    height: 28px;
    margin-right: 10px;
  }

  @media($mq-phone) {
    margin-bottom: 20px;
  }
}

.Share-item--social {
  & > * {
    vertical-align: middle;
    display: inline-block;
  }
}

.Share-subList {
  margin-left: 20px;

  & > li {
    vertical-align: middle;
    display: inline-block;
    margin-left: 4px;
  }
}

.Share-subItem {
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;

  &--fb {
    background-color: #193A83;
    &:hover, &:focus { background-color: lighten(#193A83, 10%); }
  }
  .icon--facebook {
    vertical-align: -4px;
    height: 19px;
    width: 16px;
  }

  &--tw {
    background-color: #7ABFF3;
    &:hover, &:focus { background-color: darken(#7ABFF3, 10%); }
  }
  .icon--twitter {
    vertical-align: -2px;
    height: 15px;
    width: 18px;
  }

  &--lk {
    background-color: #296DA0;
    &:hover, &:focus { background-color: lighten(#296DA0, 10%); }
  }
  .icon--linkedin {
    vertical-align: -3px;
    height: 20px;
    width: 20px;
    margin-left: 1px;
  }

  .icon { color: #fff; }
}