/*! Listening */
// Homepage block

.Listening {
  padding: 50px 0;

  .uGridM-12:not(:last-child) {
    margin-bottom: 30px;
  }

  .Btn {
    color: $color-white;
    width: 100%;
  }

  @media($mq-upto-tablet) {
    .uGridM-12:nth-child(2) {
      text-align: center;
    }

    .uGridM-12:last-child .uGridM-6 {
      display: flex;
      justify-content: center;

      & > a {
        max-width: 350px;
        width: 100%;
      }
    }
  }
}


// ---------------------
// Image
// ---------------------

.Listening-img {
  h3 { color: $color-white; }

  @media($mq-phone) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}


// ---------------------
// Buttons
// ---------------------

.Listening-btns {
  @media($mq-desktop) {
    padding-left: 40px;
  }
}


// ---------------------
// Content
// ---------------------

.Listening-content {
  @media($mq-phone) {
    margin-bottom: 40px;
  }
}