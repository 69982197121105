/*! Animations */
// Generic animations used in several components
// For a correct effect it's probably better to define:
// animation-fill-mode: both

@keyframes anim-grow-line {
  0% { right: 100%; }
  60% { right: 0%; }
  80% { right: 20%; }
  100% { right: 0%; }
}

@keyframes slide-from-right {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
