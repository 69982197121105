/*! Twitter block (home) */

.Twitter {
    overflow: hidden;
    height: 618px;
    border: 1px solid $color-lightGray;
    margin-bottom: 50px;
    padding: 30px 20px;
    background: $color-white;

    @media ($mq-upto-tablet) {
      display: none;
    }

    @media ($mq-desktop) {
      margin-top: -100px;
    }
}

.Twitter-title {
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-darkBlue;
  text-align: center;

  @extend .square, .square--center;
}
