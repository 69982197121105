/*! Services */

.Services {
  text-align: center;
  background-color: #fff;
}

.Services-title {
  @extend .square, .square--center, .square--green;
  padding-top: 40px;
  margin-bottom: 60px;
  font-family: $font-title;
  font-size: .9rem;
  text-align: center;
  text-transform: uppercase;
  color: $color-darkBlue;

  @media($mq-desktop) {
    padding-top: 110px;
    margin-bottom: 130px;
  }
}

.Services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;

  @media($mq-desktop) {
    margin-bottom: 60px;
  }
}

.Services-item {
  width: calc(100% / 2);
  margin-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;

  & > a { display: block; }

  @media($mq-tablet) { width: calc(100% / 3); }
  @media($mq-desktop) {
    width: calc(100% / 7);
    margin-bottom: 40px;
  }
  @media($mq-from-tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.Services-list + .icon {
  width: 202px;
  height: 65px;
  margin-bottom: 80px;

  @media($mq-upto-tablet) {
    display: none;
  }
}

.Services-circle {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-bottom: 10px;
  padding-top: 25px;
  background-color: $color-darkBlue;
  border-radius: 50%;

  .icon {
    width: 60px;
    height: 60px;
    vertical-align: top;
  }
  .icon[class*="icon--bck"] {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon:not([class*="icon--bck"]) {
    z-index: 2;
    position: relative;
    color: #fff;
  }
  // Size tweaks
  .icon--tool,
  .icon--directory {
    transform: scale(0.88);
  }
  .icon--radio {
    transform: translateY(-6%) scale(0.95);
  }
  .icon--warning {
    transform: translateY(-6%);
  }
  .icon--dialog {
    transform: scale(1.14);
  }
  .icon--bck1 {
    width: 130px;
    height: 130px;
  }
  .icon--bck2 {
    width: 140px;
    height: 140px;
  }
  .icon--bck3 {
    width: 113px;
    height: 113px;
  }

  img {
    vertical-align: middle;
  }

  @media($mq-desktop) {
    width: 120px;
    height: 120px;
    padding-top: 30px;
    margin-bottom: 25px;
  }
}

.Services-text {
  font-family: $font-title;
  font-weight: 700;
  line-height: 1.5;
  color: $color-black;
}