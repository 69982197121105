/*! SearchList */

.SearchList {
  padding-bottom: 20px;
}

.SearchList-item {
  margin-bottom: 25px;
  padding-bottom: 24px;
}

.SearchList > .SearchList-item:not(:last-child),
.SearchList > :not(:last-child) .SearchList-item {
  border-bottom: 1px solid rgba(48,48,48,.2);
}

.SearchList-wrap {
  position: relative;
}

.SearchList-supTitle {
  font-size: .65rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-darkBlue;

  span {
    position: relative;
    vertical-align: baseline;
    display: inline-block;
    padding-right: 15px;
    margin-right: 8px;
    font-family: $font-title;
    font-size: .7rem;
    font-weight: 500;
    color: $color-black;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 10px;
      background-color: $color-darkBlue;
    }
  }
}

.SearchList-title {
  margin-bottom: 20px;
  margin-top: 15px;
  font-family: $font-title;
  font-size: 1rem;
  line-height: 1.25;

  a {
    color: $color-black;

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.SearchList-doc {
  position: relative;
  display: block;
  width: calc(100% + 15px); // 1 - Styles for hover effect
  padding: 15px 15px 15px 65px; // 1
  margin: 5px -15px -15px; // 1
  font-size: .65rem;
  font-weight: 500;
  line-height: 1.4;
  background-image: none;
  transition: background-color 150ms ease-in-out;

  &:hover, &:focus { background-color: lighten($color-lightBlue, 20%); }

  .icon--paperclip {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
  }

  span {
    display: block;
    font-style: italic;
  }
}