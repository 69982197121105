/*! AnnuaireHead */

.AnnuaireHead {
  @extend .SearchHead;

  .icon {
    width: 70px;
    height: 70px;
  }
}

.AnnuaireGrid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;
  justify-content: center;
  text-align: center;
  background-color: transparent;

  span {
    display: block;
  }
}

.Annuaire-table {

  @media ($mq-phone) {
    .pinned,
    .table-wrapper {
      border: 0;
    }

    thead th,
    .pinned td,
    .scrollable td {
      border-left: 1px solid $color-gray;
    }

    td, th {
      padding-left: 8px;
    }

    thead tr {
      border-bottom: 1px solid $color-gray;
    }

    span {
      display: block;
    }

    .icon {
      width: 50px;
      height: 50px;
    }
  }
}


#operateur-annuaire-list-sommaire.list-sommaire {
	width: 206px !important;
	float: left;
	font-size: 0.65rem;

	@media ($mq-phone) {
	 width: 100%;
	 float: none;

	 & + div {
  	 margin: 0 !important;
  	 float: none;
  	}
	}

	.item-sommaire {
    cursor: pointer;
    background: url("../images/tree_closed.png") no-repeat 0 8px;
    text-indent: 20px;
    padding: 2px 0;

    &.open {
      background: url("../images/tree_opened.png") no-repeat 0 8px;
    }
  }

  li a.on {
    font-weight: bold;
  }
}

#operateur-annuaire-node-article {
  width: auto !important;

  input[type="text"] {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  .bg-grey:last-child {
    margin-top: 50px;
  }

  .listing {
    width: 100% !important;

    tr th {
      width: auto !important;
    }
  }
}