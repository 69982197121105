/*! Utilities */

// SVG icons

.icon {
  width: 1em;
  height: 1em;
  // Inherit text color
  fill: currentColor;
  // Don't intercept clicks in IE and Edge
  pointer-events: none;
}

// Block scrolling

.noScroll {
  overflow: hidden;
}

.noScrollMobile {
  @media ($mq-upto-tablet) {
    overflow: hidden;
  }
}

//  Centering elements

.u-center {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.u-text--center {
	text-align: center;
}

// Some legacy content uses text-center etc. classes
// e.g. for tables
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}


//  --------------------------
//  Gray background on mobile
//  --------------------------

.grayBgRwd {
  @media ($mq-upto-tablet) {
    background-color: $color-lighterGray;
  }
}

//  --------------------------
//  Round image (only works for a square image)
//  --------------------------

.u-roundImage {
  position: relative;
  z-index: 4;
  border-radius: 50%;
}


// Force underline style for links

.u-linkUnderline {
  border-bottom: solid 1px currentColor;
}
