/*! Custom fonts */
// Add font(s) on the project
//  -> Merriweather Sans: Regular, Bold
//  -> Open Sans: Regular, Regular Italic, SemiBold


// ----------------
// Merriweather Sans
// ----------------

$font-merriweathersans : '../fonts/merriweathersans/';

// Regular
@font-face {
    font-family: "Merriweather Sans";
    src:    url('#{$font-merriweathersans}400.woff2') format('woff2'),
    url('#{$font-merriweathersans}400.woff') format('woff');
    font-weight: 400;
}

// Bold
@font-face {
    font-family: "Merriweather Sans";
    src:    url('#{$font-merriweathersans}700.woff2') format('woff2'),
    url('#{$font-merriweathersans}700.woff') format('woff');
    font-weight: 700;
}


// ----------------
// Open Sans
// ----------------

$font-opensans : '../fonts/opensans/';

// Regular
@font-face {
    font-family: "Open Sans";
    src:    url('#{$font-opensans}400.woff2') format('woff2'),
    url('#{$font-opensans}400.woff') format('woff');
    font-weight: 400;
}

// Regular italic
@font-face {
    font-family: "Open Sans";
    font-style: italic;
    src:    url('#{$font-opensans}400i.woff2') format('woff2'),
    url('#{$font-opensans}400i.woff') format('woff');
    font-weight: 400;
}

// Bold
@font-face {
    font-family: "Open Sans";
    src:    url('#{$font-opensans}600.woff2') format('woff2'),
    url('#{$font-opensans}600.woff') format('woff');
    font-weight: 600;
}