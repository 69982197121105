/*! Agenda */
// Homepage block

.Agenda {
  position: relative;
  overflow: hidden;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  background: $color-green;

  @media ($mq-tablet) {
    font-size: 1rem;
  }

  @media($mq-desktop) {
    height: 470px;
    padding: 45px 20px 45px 30px;
  }

  .Agenda-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 65px;
    text-transform: uppercase;
    font-family: $font-title;

    h3 {
      font-size: 18px;
    }
  }

  .icon--arrowPrev {
    transform: rotate(180deg);
  }

  .Agenda-back {
    &:hover {
      cursor: pointer;
    }
  }
}

.zabuto_calendar {
  width: 100%;
  text-align: center;

  table.table {
    border-spacing: 0;
    border-collapse: collapse;

    th {
      font-size: 0.875em;
    }

    tr.calendar-dow {
      border-bottom: 0;
    }
  }

  .calendar-dow-header th,
  table.table tr.calendar-month-header td,
  table.table tr td.dow-clickable {
    padding: 0 !important;
  }

  table.table tr td.dow-clickable:not(.event-styled) {
    cursor: default;
  }

  .calendar-dow-header {
    border-bottom: 40px solid transparent !important;
  }

  thead th,
  .calendar-dow-header th {
    text-transform: uppercase;
    font-weight: 600;
    line-height: 19px;
    color: $color-black;
  }

  tbody td {
    width: 14.3%;
    height: 42px;
  }

  .day {
    padding: 0;

    span {
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: 50%;
      color: $color-black;
      width: 38px;
      height: 38px;
      display: block;
      vertical-align: middle;
      position: relative;
      margin: auto;
      padding: 5px;

      @media ($mq-tablet) {
        padding-top: 1px;
      }

      &.badge-event {
        border: 1px solid $color-black;

        &:hover {
          border: 1px solid $color-white;
        }
      }
    }

    &.today {
      .badge {
        background-color: $color-white;
      }
    }
  }
}

// -----------------------
// Header
// ----------------------
div.zabuto_calendar .table tr,
div.zabuto_calendar .table tr.calendar-month-header {
    th,
    th > *,
    th span,
    td div.day {
        vertical-align: middle;
        padding: 0;
    }
}

.calendar-month-header {
  position: absolute;
  right: 10px;
  top: 45px;
  width: 180px;
  font-family: $font-title;
  font-size: 0.8rem;
  font-weight: bold;
  white-space: nowrap;

  #zabuto_calendar_1hn8_nav-prev,
  #zabuto_calendar_1hn8_nav-next {
    color: $color-black;
    font-size: 1.5rem;
    line-height: 20px;
    padding: 0 10px;
  }
}


// -----------------------
// Disable background color
// ----------------------

div.zabuto_calendar .table tr.calendar-month-header th,
div.zabuto_calendar .table tr.calendar-month-header td,
div.zabuto_calendar .table tr.calendar-dow-header th,
div.zabuto_calendar .table tr th,
div.zabuto_calendar .table tr td {
  background-color: transparent;
}


// ----------------
// Wrapp
// ----------------

.Agenda-popin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid $color-gray;
  padding: 30px 30px 20px;
  background-color: #fff;
  transition: left 300ms ease-out;
  z-index: 1;

  &[data-state='close'] {
    left: 100%;
  }

  @media ($mq-phone) {
    padding: 20px;
  }

  .Agenda-back {
    margin-left: -10px;
    font-size: .7rem;
    text-transform: uppercase;
  }

  .Agenda-date {
    font-family: $font-title;
    font-size: 1rem;
    text-align: center;
  }

  .Agenda-list {
    max-height: 350px;
    overflow-y: auto;
    padding-right: 10px;
    margin-right: -15px;
  }

  .Agenda-item {
    border-bottom: 2px solid rgba(25,29,82,.1);
    padding-bottom: 15px;
    margin-top: 20px;
    margin-bottom: 20px;

    &:last-child { border-bottom: none; }
  }

  .Agenda-hours {
    position: relative;
    margin-bottom: 10px;
    font-size: .9rem;
    line-height: 1.4;
  }

  .Agenda-title {
    padding-bottom: 3px;
    margin-bottom: 5px;
    font-size: .75rem;
  }

  .Agenda-place {
    position: relative;
    padding-left: 25px;
    font-size: .75rem;
    line-height: 1.7;

    .icon--spot {
      position: absolute;
      top: calc( 50% - 10px);;
      left: 0;
      width: 15px;
      height: 20px;
    }
  }
}