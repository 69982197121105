/*! RoundImg */

.RoundImg {
  position: relative;
  display: inline-block;
  padding-top: 100%;
  width: 100%;
  font-size: 0;

  .icon {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    transition: transform 100ms ease-out;
    stroke: $color-black;
    stroke-width: 2;
    stroke-linecap: round;
  }

  // Animation was disabled for some reason (can't find the JIRA issue)
  /*
  &:hover, &:focus {
    .icon {
      transform-origin: center;
    }
    .icon--roundInt {
      transform: translate(-50%, -50%) rotate(10deg);
    }
    .icon--roundExt {
      transform: translate(-50%, -50%) rotate(-5deg);
    }
  }
  */

  &--black .icon {
    stroke: $color-black;
  }
  &--darkBlue .icon {
    stroke: $color-darkBlue;
  }
  &--lightBlue .icon {
    stroke: $color-lightBlue;
  }
  &--yellow .icon {
    stroke: $color-yellow;
  }
  &--green .icon {
    stroke: $color-green;
  }
  &--red .icon {
    stroke: $color-pink;
  }
}

.RoundImg-img {
  position: absolute;
  z-index: 4;
  border-radius: 50%;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
  width: 90%;
  height: 90%;
}
