/*! Reception note */

.note {
  .signal {
    position:relative;
    width:40px;
  }

  .barre {
    display:block;
    float:left;
    margin-right:2px;
    width:5px;
    position:absolute;
    bottom:0;
    text-indent:-9999px;

    &.reception {
      background:#000;

      &.brouilleur {
        background: $color-pink;
      }
    }

    &.other {
      background:#CCC;
      color:#CCC;
    }
  }

  .note_numerique {
    text-indent:-9999px;
  }
}