/*! Special Link */

.link--back {
  margin: 0 0 15px 20px;
  position: relative;
  display: inline-block;

  &:before {
      color: $color-black;
    border-right: 2px solid $color-black;
    border-bottom: 2px solid $color-black;
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    left: -12px;
    bottom: 10px;
    transform: rotate(135deg);
  }

  &:hover:before {
    left: -14px;
  }
}
