/*! Body */

.Body {
  background-size: contain;
  background-repeat: repeat-y;

  @media ($mq-upto-tablet) {
    padding-top: 80px;
    background-image: none!important;
  }
}

// Article variant (space for the summary)
.Body--summary .Footer {
  padding-bottom: 60px;
  @media ($mq-from-tablet) {
    padding-bottom: 80px;
  }
}

// With space reserved for the Filters sticky button (mobile only)
.Body--filters .Footer {
  @media ($mq-upto-tablet) {
    padding-bottom: 80px;
  }
}
