/*! Mea Component */
// Homepage block ("mise en avant")

.Mea {
	padding-top: 50px;
    padding-bottom: 20px;

	a {
      display: block;
      color: inherit;
    }

    a:hover .Mea-title,
    a:focus .Mea-title {
      text-decoration: underline;
    }
}

@media($mq-desktop) {
  .Mea-first { padding: 15px 0 15px 20px; }
}

.Mea-rubrique {
	opacity: 0.5;
	font-size: 0.7rem;
	text-transform: uppercase;
	margin-bottom: 10px;

  @media($mq-upto-tablet) {
    margin-top: 30px;
  }
}

.Mea-title {
	font-family: $font-title;
}

@media($mq-desktop) {
  h3.Mea-title {
  	font-size: 1.5rem;
  	line-height: 2.1rem;
  }

  h4.Mea-title {
  	font-size: 1rem;
  	line-height: 1.4rem;
  }
}

.Mea-image {
  display: block;
  margin-bottom: 30px;
  text-align: center;
}

.Mea-date {
  font-weight: 300;
  font-size: 0.7rem;
  font-family: $font-title;

  @media($mq-desktop) {
    margin-top: 20px;
    margin-bottom: 35px;
  }
}

.Mea-square {
  @extend .square, .square--center, .square--full, .square--light;
  max-width: 58px;

  @media($mq-upto-tablet) {
    margin-bottom: 10px;
  }
}

.Mea-square--yellow {
  @extend .square--yellow;
}
.Mea-square--color:nth-child(even) {
  @extend .square--blue;
}
.Mea-square--color:nth-child(odd) {
  @extend .square--pink;
}

.Mea-btn {
  min-height: 100px;
  margin-top: 30px;

  @media($mq-upto-tablet) {
    text-align: center;
  }
  @media($mq-desktop) {
    flex: 1 1 auto;
    min-height: 215px;
    margin-top: 0;
    text-align: right;
  }

  .Btn { color: $color-white; }
}