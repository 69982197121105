/*! Sort */

.Sort {
  position: relative;
  height: 1px;
  margin-top: 100px;
  margin-bottom: 35px;
  background-color: $color-black;
}

.Sort-wrap {
  position: absolute;
  bottom: 10px;
  right: 0;
  text-align: right;

  &[data-state="active"] {
    z-index: 1;
    bottom: inherit;
    transform: translateY(-50%);
    border: 1px solid $color-black;
    padding: 10px 15px 15px;
    background-color: #fff;
  }

  @media($mq-upto-tablet) {
    left: 0;
  }
}

.Sort-action {
  z-index: 10;
  position: absolute;
  top: 0;left: 0;bottom: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;

  [data-state='active'] & { display: none; }
}

.Sort-item {
  display: block;
  padding: 15px;
  cursor: pointer;

  [data-state="inactive"] &--active {
    position: relative;
    padding-right: 35px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 0;
      margin-top: -3px;
      border-style: solid;
      border-width: 7px 4.5px 0 4.5px;
      border-color: #000000 transparent transparent transparent;
    }
  }

  [data-state="inactive"] & .icon--check,
  [data-state="inactive"] &:not(&--active) { display: none; }

  [data-state="active"] &--active {
    font-weight: 600;

    .icon--check {
      vertical-align: 0;
      display: inline-block;
      width: 22px;
      height: 15px;
      margin-left: 10px;
      color: $color-darkBlue;
    }
  }
}