/*! Initialize page styles */

// ------------------------------------
// Reset (in addition to normalize.css)
// ------------------------------------

// Default box model

*, ::before, ::after {
  box-sizing: border-box;
}

// Additional reset styles for very common stuff
// Normalize tries to only remove differences between browsers.
// If we want an actual reset, we need to reset the styles of
// just a handful of elements.

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: inherit;
}

h1, h2, h3 {
  font-family: $font-title;
}

p, ul, ol, figure {
  margin: 0;
}

ul, ol {
  list-style: none;
  padding-left: 0;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}

a {
  text-decoration: none;
  color: $color-darkBlue;

  &:hover, &:focus {
    color: $color-black;
  }
}

button {
  padding: 0;
  border: none;
  background: none;
}

// Here we contradict Normalize
fieldset {
  margin: 0;
  border: 0;
  padding: 0;
}

// Force images and frames to fit their container at most
img, iframe {
  max-width: 100% !important;
}

// Other small fixes
::-ms-clear {
  display: none;
}

// --------
// Document
// --------

html {
  // Map root font-size to 20px, so that 1rem = 20px
  font-size: 125%;
}

body {
  font-family: $font-text;
  // That would be a 16px font size in most cases
  font-size: .8rem;
  line-height: 1.8;
  color: $color-black;
  background-color: #fff;
}
