/*! Module */
// Basically this is a mix of styles for legacy content,
// using selectors from the legacy HTML.

// ---------------------
// White Background
// ---------------------

.Module-whitebg {
  background: $color-white;
  padding-bottom: 20px;
}

body:not(.legacy) .node-article {
  @extend .Layout-inner;
}

.node-entete {
  width: 100% !important;
}

// Legacy content (main container)
#contenu {
  outline: none; // it can get focus because of a tabindex="-1"
  padding-top: 25px;

  @media ($mq-from-tablet) {
    padding-top: 40px;
  }
}

.Module,
.node-article {
  width: 100% !important;

  &::after {
    content: "";
    height: 0;
    display: block;
    clear: both;
  }

  // ---------------------
  // Title
  // ---------------------

  h1 {
    @extend .square;
    margin-bottom: 1.5em;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.5;

    &::after {
      margin-top: .4em;
    }

    @media ($mq-from-tablet) {
      font-size: 1.4rem;
    }
  }

  h2 {
    @extend .square, .square--center;
    font-size: .9rem;
    line-height: 1.6;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: $font-title;

    @media ($mq-from-tablet) {
      font-size: 1rem;
    }
  }

  h3 {
    @extend .square;
    font-size: .8rem;
    font-weight: 700;
    margin: 30px 0;
    font-family: $font-title;

    @media ($mq-from-tablet) {
      font-size: .9rem;
    }
  }

  // ---------------------
  // Link
  // ---------------------

  a:not(.Btn) {
    padding-bottom: 3px;
    font-weight: 700;
    color: $color-black;
    background-image: linear-gradient(to bottom, transparent 0%, transparent 95%, $color-black 95%, $color-black 100%); // Custom underline

    &:hover,
    &:focus {
      background-image: none;
    }
  }

  p > a > i {
    margin-bottom: 20px;
    display: inline-block;
  }

  // ----------------------
  // Lists
  // ----------------------

  ul:not(#operateur-annuaire-list-sommaire) {
    li {
      position: relative;
      padding-left: 30px;

      &:not([class^="child-of-parent"]):before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: $color-darkBlue;
      }
    }

    &.list-sommaire {
      width: 176px;
      border-right: 1px solid #e5e7eb;
      border-bottom: 1px solid #e5e7eb;
      margin: 0 43px 30px 0;
      float: left;

      @media($mq-desktop) {
        min-height: 200px;
      }

      li {
        padding-left: 0;

        &:before {
          width: 0;
          height: 0;
        }
      }

      + h2 {
        text-align: left;

        &:after {
          width: 0;
        }

        + h3:after {
          width: 0;
        }
      }
    }
  }

  ol {
    margin-left: 18px;
    list-style-position: outside;

    li { padding-left: 20px; }
  }


  hr {
    margin: 30px 0;
  }

  .clear-both {
    clear: both;
  }


  // ----------------------
  // Images
  // ----------------------

  img {
    display: inline-block;
    vertical-align: middle;

    &.left {
      padding-right: 10px;
    }
    &.right {
      padding-left: 10px;
    }
  }


  // ----------------------
  // Old Modules
  // ----------------------
  .bouton_recherche {
    @extend .Btn, .Btn--lightBlue;
  }

  .cadre_pointille {
    @extend .Pannel, .Pannel--big, .Pannel--spacing;
  }

  .contenu_textecontourorange {
    @extend .Pannel, .Pannel--pink;
  }

  .titre {
    @extend h2;
  }

  .soustitre {
    @extend h3;
  }

  .contenu_fondgris {

    .cartouche_chaines {
      width: 45%;
      margin-left: 40px;
      float: left;

      .chaine_text {
        @extend .Pannel-label;
      }
    }
  }

  table.listing {
    table-layout: auto;

    @media($mq-phone) {
      font-size: 0.6em;
    }
  }

  .texteRouge {
    color: #C00;
  }

  .texteBleu {
    color: #00C;
  }

  .texteNoir {
    color: #000;
  }
}

@media($mq-from-tablet) {
  .tntHead-img {
    margin-top: -117px;
  }
}