/*! Cookie banner */

.cookie-message {
  display: none;
  overflow: hidden;
  padding: 1em;
  background: $color-lightGray;
  z-index: 1001;
  position: relative;
  transition: all 1s ease-out;

  @media ($mq-phone) {
    font-size: 0.9em;

    .Btn {
    	width: 100%;
    	margin-top: 20px;
    }
  }

  @media ($mq-tablet) {
    .Btn {
    	float: right;
    	margin-top: 10px;
    }
  }

  @media ($mq-desktop) {
    p {
    	width: 75%;
    	float: left;
    }

    .Btn {
    	float: right;
    	margin: 0;
    }
  }
}
