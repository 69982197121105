/*! Pannel */

.Pannel {
  width: 100%;
  margin: 20px 0;
  font-size: 0.7rem;
  line-height: 1.2rem;
  border: 1px solid $color-lightBlue;
  background-color: $color-white;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  // Variant - color
  &--green { border-color: $color-green; }
  &--yellow { border-color: $color-yellow; }
  &--pink { border-color: $color-pink; }
  &--blue { border-color: $color-lightBlue; }
  &--black { border-color: $color-black; }

  // Variant - fill color
  &--fill { background-color: $color-lightBlue; }
  &--fill.Pannel--green { background-color: $color-green; }
  &--fill.Pannel--yellow { background-color: $color-yellow;}
  &--fill.Pannel--pink { background-color: $color-pink; }
  &--fill.Pannel--blue { background-color: $color-lightBlue; }
  &--fill.Pannel--black { background-color: $color-black; }

  .Pannel-label {
    background: $color-lightBlue;
    color: $color-white;

    font-family: $font-title;
    font-size: 1rem;
    padding: 10px;
    width: 50px;
    text-align: center;
    margin-right: 20px;
    display: flex;

    span {
      margin: auto;
    }

  }

  .Pannel-content {
    padding: 10px;
    flex: 1;
  }

  // Variant - padding
  &--big {
    .Pannel-content {
      padding: 30px;
    }
  }
  &--medium {
    .Pannel-content {
      padding: 20px;
    }
  }

  // Variant - margin
  &--spacing {
    margin: 40px 0;
  }
}
