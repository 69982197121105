/*! PressList */

.PressList {
  margin-top: 30px;
  margin-bottom: 50px;
}

.PressList-item {
  position: relative;
  margin-bottom: 25px;

  @media ($mq-from-tablet) {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }
}

.PressList-img {
  width: 200px;
  height: auto;
  margin-bottom: 4px;

  @media ($mq-from-tablet) {
    margin-top: 4px; // vertical alignment with title text
    margin-right: 20px;
    margin-bottom: 0;
  }
}

.PressList-class {
  display: inline-block;
  border: 1px solid $color-black;
  padding: 6px 10px;
  font-size: .7rem;
  line-height: 1;
}

// This class manage the link : in tablet/desktop, the link take all the component. On phone, it's just on the title
.PressList-title {
  display: block;
  margin-bottom: .35em;
  font-size: 1rem;
  line-height: 1.6;

  @media ($mq-from-tablet) {
    font-size: 1.3rem;
    line-height: 1.5;
  }

  a {
    display: block;
  }

  a:hover,
  a:focus {
    text-decoration: underline;
  }

  // Make the clickable area cover the whole page
  @media ($mq-from-tablet) {
    a::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.PressList-date {
  margin-top: .4em;
  font-weight: 400;
  font-style: italic;
}
