/*! Square */
// Color block under titles

.square {
  position: relative;
  font-weight: 700;

  &::after {
    content: '';
    display: block;
    margin-top: 4px;
    width: 34px;
    height: 12px;
    background-color: $color-lightBlue;

    @media ($mq-from-tablet) {
      height: 14px;
      width: 40px;
      margin-top: 6px;
    }
  }

  // Variant - position
  &--center::after {
    margin-left: auto;
    margin-right: auto;
  }

  // Variant - color
  &--green::after { background-color: $color-green; }
  &--yellow::after { background-color: $color-yellow; }
  &--pink::after { background-color: $color-pink; }
  &--blue::after { background-color: $color-lightBlue; }
  &--black::after { background-color: $color-black; }

  // Variant - size
  &--big::after { width: 60px; }
  &--full::after { width: 100%; }

  // Variant - height
  &--light::after {
    height: 3px;
    margin-top: 12px;
  }
}
