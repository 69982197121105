// ======
// Mixins
// ======


// Switch to grayscale font smoothing on OSX
@mixin osx-font-smoothing-grayscale {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Visually hide an element, but keep it readable in e.g. a screen reader
@mixin visually-hidden {
  position: absolute!important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  width: 1px !important;
}
