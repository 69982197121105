

.rss--cat {
  border: 1px solid $color-gray;
  color: $color-black;
  margin: 0;
  margin-bottom: 15px;
  transition: all .2s ease-out;
  
  &:hover {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
    transform: translateY(-6px);
  }

  a {
    // display: block;
  }
}

.rss-title--wrapper {
  text-align: center;
  margin: 0;
  padding: 15px;

  @media ($mq-phone) {
    border-bottom: 1px solid $color-gray;
    width: 100%;
  }

  @media ($mq-desktop) {
    border-right: 1px solid $color-gray;
  }

  @media ($mq-tablet) {
    border-right: 1px solid $color-gray;
  }

  .icon--feed {
    display: block;
    margin: 0 auto 15px;
    width: 64px;
    height: 64px;
  }

  .rss--title {
    font-weight: bold;
  }
}

.rss--content {
  padding: 15px;
  margin: 0 0 0 25px;

  @media ($mq-phone) {
    width: 100%;
    margin-left: 0;
  }
}