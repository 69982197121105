/*! StyleGuide component */

.StyleGuide {
    h2 {
        margin-bottom: 2rem!important;
        font-size: 1.5rem!important;
    }
}

.StyleGuide-title {
    font-size: 2.5rem!important;
    text-align: center;
}

.StyleGuide-color {
    display: block;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    border: 3px solid #fff;
    border-radius: 50%;

    &--black { background-color: $color-black; }
    &--darkBlue { background-color: $color-darkBlue; }
    &--lightBlue { background-color: $color-lightBlue; }
    &--yellow { background-color: $color-yellow; }
    &--green { background-color: $color-green; }
    &--red { background-color: $color-pink; }
}
