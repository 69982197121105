/*! Breadcrumb */

.Breadcrumb-list {
  margin-left: -10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0;

  li {
    vertical-align: middle;
    display: inline-block;
    font-size: .65rem;

    &:first-child a {
      font-size: .6rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    &:last-child a:after { display: none; }
  }
  
  a {
    position: relative;
    display: inline-block;
    padding: 10px 0 10px 10px;
    color: #fff;

    &:after {
      content: '';
      vertical-align: baseline;
      display: inline-block;
      height: 8px;
      width: 1px;
      margin-left: 10px;
      background-color: #fff;
    }

    &:hover:before, &:focus:before {
      right: 0;
      animation: anim-grow-line 450ms ease;
    }
  }

  @media($mq-upto-tablet) {
    li:not(:nth-last-child(2)) { display: none; }
  }
}

// Manage Tablet / mobile back button
a.Breadcrumb-btn {
  @media($mq-upto-tablet) {
    border: 2px solid #fff;
    padding: 5px 20px 5px 10px;
    margin-top: 10px;

    i {
      font-size: .6rem;
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
    }
    &:after { display: none; }
  }
  @media($mq-desktop) {
    i { display: none; }
  }
}