/*! Bref */
// Homepage block

.bref {
  border: 1px solid $color-lightGray;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 300px;
  font-family: $font-title;
  font-size: 1.125em;

  .Teaser-title { font-size: 1em; }

  a {
    color: $color-black;
    text-align: center;
    display: block;

    &:hover { text-decoration: underline; }

    &:not(:last-child):after {
      content: "";
      width: 180px;
      height: 2px;
      display: block;
      margin: 20px auto;
      box-sizing: border-box;
      border: 1px solid $color-black;
    }
  }

  @media ($mq-desktop) {
    padding: 40px 30px;

    &:not(:last-child):after {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }
}