/*! Accordion component */
// Used in the FAQ page

.Accordion {
  border: 2px solid $color-gray;
}

.Accordion-item {
  position: relative;
  overflow: hidden;

  &:not(:first-child) {
    border-top: 2px solid $color-gray;
  }
}

.Accordion-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 1em 40px 1em 20px;
  font-size: 100%;
  font-weight: bold;
  line-height: 1.5;
  color: $color-darkBlue;
  background-color: $color-white;
  cursor: pointer;

  // Right arrow
  &::after {
    content: "\25B6";
    position: absolute;
    right: 10px;
    top: calc(50% - 15px);
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: transform 250ms ease-out;
  }
}

.Accordion-content {
  display: none;
  padding: 20px;
  color: $color-black;
  background-color: white;
}

// Open state

.Accordion-item--open {
  .Accordion-title {
    color: $color-white;
    background-color: $color-darkBlue;
  }
  .Accordion-title::after {
    transform: rotate(90deg);
  }
  .Accordion-content {
    display: block;
  }
}
