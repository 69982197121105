/*! SearchHead */

.SearchHead {
  padding-bottom: 30px;
  color: #fff;
  background-color: $color-darkBlue;

  @media($mq-desktop) {
    padding-bottom: 60px;
  }
}

.SearchHead-title {
  @extend .square, .square--green;
  padding-bottom: 30px;
  padding-top: 20px;
  font-size: 1rem;

  @media($mq-from-tablet) {
    padding-bottom: 60px;
    padding-top: 40px;
    font-size: 1.2rem;
  }
}

.SearchHead-list {
  margin-top: -10px;
}

.SearchHead-item {
  display: inline-block;
  height: 40px;
  border: 1px solid #fff;
  margin-bottom: 10px;
  margin-right: 4px;
  padding: 6px 20px 5px 10px;
  font-size: .75rem;

  .icon--cross {
    vertical-align: 0;
    display: inline-block;
    height: 11px;
    width: 11px;
    margin-right: 8px;
    color: $color-white;
  }
}

@media ($mq-phone) {
  .SearchHead-item:first-child {
    margin-top: 20px;
  }
}

.SearchHead-btn {
  display: inline-block;
  height: 40px;
  padding: 6px 20px 5px 10px;
  font-size: .75rem;
  color: $color-darkBlue;
  background-color: #fff;
  vertical-align: top;

  &:hover {
    cursor: pointer;
  }
}


// ------------------------------------
// Search form (similar to Search-form)
// ------------------------------------

.SearchHead-form {
  position: relative;
  margin-bottom: 40px;

  @media ($mq-from-tablet) {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
  }
}

.SearchHead-form .icon--search {
  display: none;

  @media ($mq-from-tablet) {
    display: block;
    flex: 1 0 auto;
    height: 44px;
    width: 40px;
    margin-top: 20px;
    margin-bottom: 25px;
  }
  @media ($mq-desktop) {
    height: 54px;
    width: 50px;
  }
}

.SearchHead-form .Btn {
  width: 100%;
  color: #fff;

  @media ($mq-from-tablet) {
    flex: 2 0 auto;
    align-self: center;
    min-width: inherit;
    width: 160px;
    max-width: 260px;
  }
}

.SearchHead-inputWrapper {
  margin: 30px 0;

  input {
    display: block;
    width: 100%;
    padding: 0 10px;
    border: none;
    border-bottom: 1px solid $color-white;
    height: 2em;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    appearance: none;
    &:focus {
      color: $color-black;
      background: $color-white;
      transition: background-color 250ms linear;
    }
    &::-webkit-input-placeholder { color: #fff; opacity: 1; }
    &::-moz-placeholder { color: #fff; opacity: 1; }
    &:-ms-input-placeholder { color: #fff; opacity: 1; }
  }

  @media ($mq-from-tablet) {
    flex: 2 1 auto;
    min-width: 200px;
    margin: 0 30px;

    input {
      width: 100%;
      font-size: 1.6rem;
    }
  }

  @media ($mq-desktop) {
    input { 
      height: 1.5em;
      // avoid vertical padding, because it crops the text on some browsers
      // (or use smaller text)
      padding: 0 10px;
      font-size: 2rem;
    }
  }
}
