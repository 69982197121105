/*! MaTNT component */

.MaTNT-map {
  margin-bottom: 20px;
}

.MaTNT-transmitter {
  font-size: 0.9rem;
}

.MaTNT-note {
  font-size: 0.65rem;
  font-style: italic;
}

#matnt {
  table  {
    margin: 0;

    tr {
      border: none;

      td {
        @extend .Pannel;
      }
    }
  }

  #results img {
      max-width: initial !important;
  }


  #viewerDiv {
      max-width: 100%;
      height:600px;
      visibility:hidden;
      font-size: 15px;

      @media($mq-desktop) {
        width: 750px;
      }

      .gpMainMap {
        max-width: 100%;
      }
  }
  #viewerCache {
      /*width:750px;*/
      visibility:hidden;
      background-color:white;
      background-image:url('http://api.ign.fr/geoportail/api/js/latest/theme/geoportal/img/loading.gif');
      background-position:center center;
      background-repeat:no-repeat;

      @media($mq-desktop) {
        height:600px;      
      }
      
      @media($mq-upto-tablet) {
        .cadre_pointille {
          float: none !important;
          width: auto !important;
        }
      }
  }


  a.bulle {
  	position: relative;
  	display: inline;
  }
  a.bulle span {
  	font-family: Arial, Helvetica, sans-serif;
  	font-size: 10px;
  	position: absolute;
  	width:300px;
  	color: #000000;
  	background: #FFFFFF;
  	padding: 5px;
  	line-height: 12px;
  	text-align: center;
  	visibility: hidden;
  	border-radius: 6px;
  	-moz-box-shadow: 0px 4px 10px #333333;
  	-webkit-box-shadow: 0px 4px 10px #333333;
  	box-shadow: 0px 4px 10px #333333;
  	border:1px solid #999999;
  }
  a.bulle span:after {
  	content: '';
  	position: absolute;
  	top: 100%;
  	left: 50%;
  	margin-left: -8px;
  	width: 0; height: 0;
  	border-top: 8px solid #FFFFFF;
  	border-right: 8px solid transparent;
  	border-left: 8px solid transparent;
  }
  a:hover.bulle span {
  	visibility: visible;
  	opacity: 0.95;
  	bottom: 30px;
  	left: 50%;
  	margin-left: -150px;
  	z-index: 999;
  	text-decoration:none;
  }
  a.bulle_image {
  	position: relative;
  	display: inline;
  }
  a.bulle_image span {
  	font-family: Arial, Helvetica, sans-serif;
  	font-size: 10px;
  	position: absolute;
  	color: #000000;
  	background: #FFFFFF;
  	padding: 5px;
  	line-height: 12px;
  	text-align: center;
  	visibility: hidden;
  	border-radius: 6px;
  	-moz-box-shadow: 0px 4px 10px #333333;
  	-webkit-box-shadow: 0px 4px 10px #333333;
  	box-shadow: 0px 4px 10px #333333;
  	border:1px solid #999999;
  }
  a.bulle_image span:after {
  	content: '';
  	position: absolute;
  	top: 100%;
  	left: 50%;
  	margin-left: -8px;
  	width: 0; height: 0;
  	border-top: 8px solid #FFFFFF;
  	border-right: 8px solid transparent;
  	border-left: 8px solid transparent;
  }
  a:hover.bulle_image span {
  	visibility: visible;
  	opacity: 1;
  	bottom: 30px;
  	left: 50%;
  	z-index: 999;
  	text-decoration:none;
  }
  a.bulle_image img {
  	min-width: 250px;
  }
}