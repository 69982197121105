/*! ArtHead */

// ---------------------
// Main Containers
// ---------------------

.ArtHead {
  padding-bottom: 10px;
  padding-top: 20px;
  background-image: url('../images/hero2.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;

  @media($mq-phone) {
    margin-bottom: 30px;
  }
  @media($mq-from-tablet) {
    padding-top: 60px;
    padding-bottom: 75px;
  }
}

.ArtHead-inner {
  @media($mq-phone)  {
    font-size: 0;

    & > * { font-size: .8rem; }
  }
  @media($mq-from-tablet) {
    display: flex;
    align-items: flex-start;

    &--half > * {
      flex: 0 1 auto;
      width: 50%;
    }
  }
}


// ---------------------
// Image
// ---------------------

.ArtHead-img {
  height: auto;

  @media($mq-phone) {
    max-width: inherit;
    width: calc(100% + 40px);
    //margin-left: -20px;
    //margin-top: 20px;
    margin: 20px auto;
  }
  @media($mq-from-tablet) {
    order: 1;
  }
  @media($mq-tablet) {
    flex: 0 1 auto;
    width: 50%;
  }
}


// ---------------------
// Content
// ---------------------

.ArtHead-wraper {
  @media($mq-from-tablet) {
    order: 2;
    padding-top: 25px;
    margin-left: 25px;
  }

  @media ($mq-desktop) {
    flex: 0 1 auto;
  }
}

.ArtHead-title {
  overflow: hidden;
  //max-height: 4.2em;
  margin-bottom: 15px;
  font-family: $font-title;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;

  @media($mq-desktop) {
    font-size: 1.8rem;
  }
}

.ArtHead-date {
  font-style: italic;
}