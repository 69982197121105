/*! Table styles */
// Generic style for all content tables
// Note that Zabuto Calendar uses a <table class="table"> element,
// so we are using a :not() selector to avoid styling it.

table:not(.table) {
  width: 100%;
  // avoid fixed layout, or text will overflow on mobile!
  table-layout: auto;
  margin-top: 25px;
  margin-bottom: 30px;
  border-collapse: collapse;

  @media($mq-from-tablet) {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  caption {
    margin-bottom: 20px;
    font-family: $font-title;
    font-size: .8rem;
    text-align: left;
  }

  tr {
    border-bottom: solid 1px #BFBFBF;
  }

  tbody > tr:last-child {
    border-bottom: solid 2px $color-black;
  }

  th, td {
    vertical-align: top;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
  }

  th {
    font-family: $font-title;
    font-size: .75rem;
    line-height: 1.4; // down from the body's 1.8
    color: $color-darkBlue;
  }

  td {
    font-size: .7rem;
    line-height: 1.6; // down from the body's 1.8
  }

  tr > :nth-child(n+2) {
    padding-left: 6px;
  }
  tr > :nth-last-child(n+2) {
    padding-right: 6px;
  }

  @media ($mq-from-tablet) {
    caption {
      margin-bottom: 30px;
      font-size: 1rem;
    }
    th, td {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    th {
      font-size: .9rem;
    }
    td {
      font-size: .8rem;
    }
    tr > :nth-child(n+2) {
      padding-left: 12px;
    }
    tr > :nth-last-child(n+2) {
      padding-right: 12px;
    }
  }

  &.Radio-table th {
    text-align: center;
    padding-top: 30px;
  }

  // Support alignments declared in the HTML (from database)
  [align="center"],
  .text-center {
    text-align: center;
  }

  [align="right"],
  .text-right {
    text-align: right;
  }

  &.center {
    margin: 0 auto;
    text-align: center;

    th,
    td,
    a,
    img {
      text-align: center;
    }
  }
}

// Responsive Table
// With a <div class="resp-table"> wrapper added in JS
$mobile-horizontal-padding: 20px;

.resp-table {
  @media ($mq-phone) {
    margin: 25px -#{$mobile-horizontal-padding} 30px;
    overflow-x: auto;

    & > table {
      margin: 0 !important;
    }
    tr > :first-child {
      padding-left: #{$mobile-horizontal-padding} !important;
    }
    tr > :last-child {
      padding-right: #{$mobile-horizontal-padding} !important;
    }
  }
}
