/*! Layout component */

.Layout {
    &--blue {
        color: #fff;
        background-color: $color-darkBlue;
    }
    &--white {
      background-color: #fff;
    }
    &--border {
      border-bottom: 1px solid rgba(47,47,47,.2);
    }
    &--padding {
      padding-top: 30px; padding-bottom: 30px;
    }
}

.Layout-inner {
  max-width: 1220px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
