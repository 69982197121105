/*! CatHead */

// ---------------------
// Main Containers
// ---------------------

.CatHead {
  padding-top: 20px;
  padding-bottom: 40px;
  background-image: url('../images/hero.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100%;

  @media($mq-upto-tablet) {
    margin-bottom: 60px;
  }
  @media($mq-desktop) {
    padding-bottom: 160px;
  }
}

.CatHead-inner {
  @media($mq-desktop) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}


// ---------------------
// Content
// ---------------------

.CatHead-wraper {
  @media($mq-desktop) {
    flex: 0 1 50%;
  }
}

.CatHead-title {
  @extend .square, .square--big, .square--pink;
  padding-bottom: 35px;
  margin-bottom: 1em;
  font-family: $font-title;
  font-size: 1.2rem;

  @media($mq-desktop) {
    font-size: 2.4rem;
  }
}

.CatHead-text {
  font-weight: 600;

  p:not(:last-child) { margin-bottom: 1.5rem; }
}


// ---------------------
// Image
// ---------------------

.CatHead-img {
  flex: 0 1 41%;
  height: auto;
  min-width: 300px;
  margin-left: 9%;

  img {
    width: 100%;
    height: auto;
  }

  @media($mq-upto-tablet) {
    display: none;
  }
}
