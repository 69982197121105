/*! Filters  */


// ---------------------
// Wrapper
// ---------------------

.Filters-wrapper {
  @media($mq-upto-tablet) {
    position: fixed;
    z-index: $zindex-filters;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;

    .Filters[data-state="active"] & {
      transform: translateX(0);
    }
  }
}

.Filters-open {
  z-index: 4;
  position: fixed;
  bottom: 10px;
  left: 50%;
  margin-left: -140px;

  span {
    z-index: 5;
    position: relative;
    display: block;
    height: 50px;
    width: 280px;
    line-height: 50px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    background-color: $color-darkBlue;
    cursor: pointer;
  }

  .icon--filters {
    vertical-align: -7px;
    width: 27px;
    height: 25px;
    margin-left: 10px;
  }

  &:before {
    content: "";
    z-index: 3;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70px;
    background-color: rgba(255,255,255,.8);
  }

  @media($mq-desktop) { display: none; }
}

.Filters-close {
  z-index: 10;
  position: absolute;
  top: 24px;
  right: 20px;
  padding: 10px;
  line-height: 1;
  color: $color-darkBlue;
  cursor: pointer;

  .icon--cross {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  @media ($mq-desktop) {
    display: none !important;
  }
}

// The scrollable part (mobile only)
.Filters-scrollview {
  @media ($mq-upto-tablet) {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px;
  }
}


// ---------------------
// Title
// ---------------------

.Filters-title {
  @extend .square;
  margin-bottom: 40px;
  margin-top: 30px;
  font-family: $font-title;
  font-weight: 700;
  text-transform: uppercase;
  color: $color-darkBlue;

  @media($mq-desktop) {
    margin-top: 45px;
  }
}


// ---------------------
// Section
// ---------------------

.Filters-section {
  padding-right: 35px;

  &:not(:first-of-type) {
    position: relative;
    margin-top: 35px;
    padding-top: 35px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(48,48,48,.2);
    }
  }

  .icon--pen  {
    width: 10px;
    height: 43px;
    margin-top: 12px;
    transform: rotate(35deg);
  }

  .icon--calendar  {
    width: 34px;
    height: 32px;
    margin-top: 15px;
  }

  @media($mq-desktop) {
    &:not(:first-of-type) {
      margin-top: 50px;
      padding-top: 40px;

      &:after { width: 1000px; } // Secure distance
    }
  }
}

.Filters-sectionT {
  position: relative;
  padding-top: 22px;
  padding-bottom: 23px;
  padding-left: 84px;
  font-family: $font-title;
  font-weight: 700;
  font-size: .9rem;
  line-height: 1.2;
  margin-bottom: 30px;

  span {
    position: absolute;
    left: 0;
    top: 0;
    vertical-align: middle;
    display: inline-block;
    height: 66px;
    width: 66px;
    line-height: 66px;
    text-align: center;
    color: #fff;
    background-color: $color-darkBlue;
    border-radius: 50%;
  }
}


// ---------------------
//  Input + label
// ---------------------

.Filters input[type="checkbox"] {
  position: absolute;
  top: 5px;
  left: 0;
  display: inline-block;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  border: 1px solid #2f2f2f;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-color: $color-darkBlue;
    background-image: url('../images/check.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.Filters div[id="search_queryFilter"] {
  width: 100%;
}


// ---------------------
//  Filter
// ---------------------

.Filters-filter {
  position: relative;
  margin-bottom: 5px;
  padding-left: 28px;

  label { cursor: pointer; }

  .Filters-subFilter & {
    margin-left: 30px;
    font-size: 15px;
  }
}


// ---------------------
//  Sub Filter
// ---------------------

.Filters-subFilter {
  border-top: 1px solid rgba(48,48,48,.2);
  margin-top: 20px;
  padding-top: 15px;
}

.Filters-subTitle {
  position: relative;
  margin-bottom: 15px;
  padding-left: 28px;
  font-weight: 700;
  cursor: pointer;

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-black;
  }
  &:before {
    right: 0;
    width: 19px;
    height: 3px;
  }
  &:after {
    right: 8px;
    width: 3px;
    height: 19px;
  }

  [data-state="active"] &:after { display: none; }
}


// ---------------------
//  Button
// ---------------------

.Filters-btn {
  position: relative;
  margin-top: 40px;
  padding-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1000px; // Secure distance
    height: 1px;
    background-color: rgba(48,48,48,.2);
  }

  @media($mq-desktop) {
    margin-top: 60px;
    padding-bottom: 60px;
  }
}