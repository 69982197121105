/*! Btn component */

.Btn {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  min-width: 240px;
  padding: 12px 20px;
  font-family: $font-title;
  font-size: .7rem;
  font-weight: 700;
  text-align: center;
  color: $color-black;
  cursor: pointer;
  // Color for the SVG border
  stroke: $color-black;
}

.Btn-border {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // inherit stroke color from parent
  stroke: inherit;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 420px;
  stroke-dashoffset: 100px;
  // make sure we don't intercept click events in IE and Edge
  pointer-events: none;
}

.Btn:hover,
.Btn:focus {
  color: $color-black;
  .Btn-border {
    animation: btn-border-draw 1s cubic-bezier(.55,.01,.55,1.55) forwards;
  }
}

.Btn--spacing {
  margin: 14px 0;
}

@media($mq-upto-tablet) {
  .Btn--full {
    display: block;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

// ---------------------
// Variants
// ---------------------

.Btn--black {
  stroke: $color-black;
}
.Btn--darkBlue {
  stroke: $color-darkBlue;
}
.Btn--lightBlue {
  stroke: $color-lightBlue;
}
.Btn--yellow {
  stroke: $color-yellow;
}
.Btn--green {
  stroke: $color-green;
}
.Btn--red {
  stroke: $color-pink;
}

// ---------------------
// Animation
// ---------------------

@keyframes btn-border-draw {
  0% {
    stroke-dashoffset: 420px;
  }

  100% {
    stroke-dashoffset: 100px;
  }
}
