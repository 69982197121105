/*! Grid utility classes */


$gutter: 40px;

.uGridL,
.uGridM,
.uGridS {
  background-color: #fff;
}

.uGridL--transparent,
.uGridM--transparent,
.uGridS--transparent {
  background: transparent;
}

@mixin ugrid-make($class) {
  $selector: unquote("." + $class);
  #{$selector} {
    display: flex;
    flex-wrap: wrap;
    margin-left: -$gutter;

    // Alignment variants
    &--center { justify-content: center; }
    &--middle { align-items: center; }
    &--bottom { align-items: flex-end; }

    // Ordering items (default order is similar to 0)
    &-first { order: -1; }
    &-last { order: 10; }

    // Items
    &-0 { display: none; }
    &-1 { flex: 0 1 auto; width: calc(8.3% - #{$gutter}); margin-left: $gutter; }
    &-2 { flex: 0 1 auto; width: calc(16.6% - #{$gutter}); margin-left: $gutter; }
    &-3 { flex: 0 1 auto; width: calc(25% - #{$gutter}); margin-left: $gutter; }
    &-4 { flex: 0 1 auto; width: calc(33.3% - #{$gutter}); margin-left: $gutter; }
    &-5 { flex: 0 1 auto; width: calc(41.6% - #{$gutter}); margin-left: $gutter; }
    &-6 { flex: 0 1 auto; width: calc(50% - #{$gutter}); margin-left: $gutter; }
    &-7 { flex: 0 1 auto; width: calc(58.3% - #{$gutter}); margin-left: $gutter; }
    &-8 { flex: 0 1 auto; width: calc(66.6% - #{$gutter}); margin-left: $gutter; }
    &-9 { flex: 0 1 auto; width: calc(75% - #{$gutter}); margin-left: $gutter; }
    &-10 { flex: 0 1 auto; width: calc(83.3% - #{$gutter}); margin-left: $gutter; }
    &-11 { flex: 0 1 auto; width: calc(91.6% - #{$gutter}); margin-left: $gutter; }
    &-12 { flex: 0 1 auto; width: calc(100% - #{$gutter}); margin-left: $gutter; }
  }
}

@media ($mq-desktop) {
  @include ugrid-make("uGridL");
}

@media ($mq-tablet) {
  @include ugrid-make("uGridM");
}

@media ($mq-phone) {
  @include ugrid-make("uGridS");
}

// Reordering content

.uGrid--2-1-3 {

}
