/*! Highligh */
// Homepage block

// -----------------------
// Main
// -----------------------

.Highlight {
  padding: 50px 0;
  position: relative;
  background: $color-white;
  border-top: 1px solid rgba(47,47,47,.2);
  font-size: 1.125em;

  .Teaser-title { font-family: $font-title; }
}


// -----------------------
// Image
// -----------------------

.Highlight-img {
  @media($mq-phone) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}


// -----------------------
// Content
// -----------------------

.Highlight-content {
  @media ($mq-phone) {
    font-size: .8rem;
  }
  @media ($mq-tablet) {
    margin-top: 20px;
  }
  @media ($mq-desktop) {
    margin-right: 40px;
  }
}


// -----------------------
// Btns
// -----------------------

.Highlight-btns {
  @media ($mq-upto-tablet) {
    margin-top: 25px;
    text-align: center;

    & > * {
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }
  @media ($mq-tablet) {
    & > * { margin-left: 40px; }
  }
  @media ($mq-desktop) {
    & > * {
      width: calc(100% - 40px);
      margin-left: 40px;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: rgba(47, 47, 47, .2);
    }
  }
}