/*! Search */

// ---------------------
// Main container
// ---------------------

.Search {
  vertical-align: middle;
  display: inline-block;
}


// ---------------------
// Open Btn
// ---------------------

.Search-btn {
  cursor: pointer;

  .icon--searchheader {
    color: $color-darkBlue;
  }

  @media ($mq-upto-tablet) {
    right: 10px;

    span {
      display: block;
      font-size: .5rem;
      text-transform: uppercase;
      color: #fff;

      [data-state="active"] & {
        display: none;
      }
    }

    .icon--searchheader {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-bottom: 5px;
      background-color: $color-menuGray;
    }
  }

  @media ($mq-desktop) {
    .icon--search {
      width: 32px;
      height: 35px;
      transition: color 250ms ease-in-out;
    }

    &:hover .icon--search,
    &:focus .icon--search {
      color: $color-pink
    }
  }
}


// ---------------------
// Close btn
// ---------------------

.Search-close {
  z-index: 10;
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  color: #fff ;
  cursor: pointer;

  .Search[data-state="inactive"] & {
    display: none;
  }

  .Search[data-state="active"] & {
    animation: slide-from-right 250ms 150ms both;
  }

  .icon--cross {
    display: block;
    height: 20px;
    width: 20px;
  }

  @media ($mq-upto-tablet) {
    span {
      @include visually-hidden;
    }
  }

  @media ($mq-desktop) {
    position: absolute;
    top: 40px;
    right: 10px;

    span {
      vertical-align: middle;
      display: inline-block;
      max-width: 85px;
      margin-right: 20px;
      font-size: .7rem;
      text-align: right;
      text-transform: uppercase;
    }
    .icon--cross {
      vertical-align: middle;
      display: inline-block;
      height: 29px;
      width: 29px;
    }
  }
}


// ---------------------
// Popin
// ---------------------

.Search-popin {
  z-index: $zindex-search;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
  color: #fff;
  background-color: $color-darkBlue;
  transform: translateX(100%);
  transition: transform 250ms ease-in-out;

  .Search[data-state="active"] & {
    transform: translateX(0);
  }

  & > .Layout-inner {
    position: relative;
  }

  @media ($mq-phone) {
    .icon--logo { display: none; }
  }

  @media ($mq-tablet) {
    .icon--logo {
      width: 125px;
      height: 50px;
      margin-top: 15px;
    }
  }

  @media ($mq-desktop) {
    .icon--logo {
      width: 250px;
      height: 100px;
      margin-top: 20px;
    }
  }
}


// ---------------------
// Form
// ---------------------

.Search-form {
  position: relative;

  @media ($mq-from-tablet) {
    display: flex;
    align-items: center;
    margin-top: 50px;
  }
  @media ($mq-desktop) {
    margin-top: 80px;
  }

  .Search[data-state="inactive"] & {
    opacity: 0;
  }
  .Search[data-state="active"] & {
    opacity: 1;
    animation: slide-from-right 250ms 200ms forwards;
  }
}

.Search-form .icon--search {
  height: 44px;
  width: 40px;
  margin-top: 20px;
  margin-bottom: 25px;
  @media ($mq-from-tablet) {
    flex: 1 0 auto;
  }
  @media ($mq-desktop) {
    height: 54px;
    width: 50px;
  }
}

.Search-form .Btn {
  width: 100%;
  color: #fff;

  @media ($mq-from-tablet) {
    flex: 2 0 auto;
    align-self: center;
    min-width: inherit;
    width: 160px;
    max-width: 260px;
  }
}

.Search-inputWrapper {
  margin-top: 30px;
  margin-bottom: 30px;

  input {
    display: block;
    width: 100%;
    padding: 0 10px;
    border: none;
    border-bottom: 1px solid $color-white;
    height: 2em;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: transparent;
    appearance: none;
    &:focus {
      color: $color-black;
      background: $color-white;
      transition: background-color 250ms linear;
    }
    &::-webkit-input-placeholder { color: #fff; opacity: 1; }
    &::-moz-placeholder { color: #fff; opacity: 1; }
    &:-ms-input-placeholder { color: #fff; opacity: 1; }
  }

  @media ($mq-from-tablet) {
    flex: 2 1 auto;
    min-width: 200px;
    margin: 0 30px;

    input {
      width: 100%;
      font-size: 1.6rem;
    }
  }

  @media ($mq-desktop) {
    input { 
      height: 1.5em;
      // avoid vertical padding, because it crops the text on some browsers
      // (or use smaller text)
      padding: 0 10px;
      font-size: 2rem;
    }
  }
}


// ---------------------
// Sub Form
// ---------------------

.Search-subForm {
  display: none;
  position: relative;
  margin-bottom: 60px;
  text-align: right;

  // Decoration lines
  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: inherit;
    width: 40px;
    right: 0;
    height: 2px;
    background-color: #fff;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 60px;
    background-color: #fff;
  }

  @media ($mq-from-tablet) {
    display: block;
  }
}

.Search-subForm a {
  position: relative;
  display: inline-block;
  margin-right: -10px;
  padding: 10px;
  font-family: $font-title;
  font-weight: 700;
  text-align: center;
  color: #fff;

  &:hover, &:focus {
    text-decoration: underline;
  }
}


// ---------------------
// Footer
// ---------------------

.Search-footer {
  margin-top: 40px;
  padding-top: 40px;

  a {
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
    color: #fff;

    &:hover, &:focus { text-decoration: underline; }
  }

  .Search[data-state="active"] & {
    opacity: 0;
    animation: slide-from-right 250ms 300ms forwards;
  }

  @media ($mq-phone) {
    border-top: 2px solid rgba(255,255,255,.3);
  }
  @media ($mq-from-tablet) {
    display: flex;
    align-items: flex-start;
  }
}

.Search-title {
  @extend .square;
  margin-bottom: 30px;
  font-family: $font-title;
  text-transform: uppercase;

  .Search-ressources & { @extend .square--green; }
}

.Search-common,
.Search-ressources {
  margin-bottom: 40px;

  @media ($mq-tablet) {
    width: 50%;
  }
  @media ($mq-from-tablet) {
    flex: 0 1 auto;
  }
}

.Search-common {
  @media ($mq-desktop) {
    width: calc(100% / 3 * 2);
  }
}

.Search-cols {
  @media ($mq-desktop) {
    column-count: 2;
  }
}

.Search-ressources {
  a {
    font-family: $font-title;
    font-weight: 700;
  }

  @media ($mq-desktop) {
    width: calc(100% / 3);
  }
}
