/*! Nav */

// ---------------------
// Main
// ---------------------

.Nav {
  vertical-align: top;
  display: inline-block;

  @media ($mq-desktop) {
    vertical-align: middle;
  }
}

.Nav--search {
  @media ($mq-upto-tablet) {
    position: absolute;
    right: 50px;
    top: 0;
  }
  @media ($mq-desktop) {
    display: none;
  }
}


// ---------------------
// Btn
// ---------------------

.Nav-btn {
  padding: 10px;

  .icon--burger {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $color-menuGray;
    margin-bottom: 5px;
  }

  span {
    display: block;
    font-size: .5rem;
    text-transform: uppercase;
    color: #fff;

    i { @include visually-hidden; }
  }

  @media ($mq-upto-tablet) {
    position: absolute;
    right: 95px;
    top: 0;

    span { font-family: $font-title; }

    [data-state="inactive"] & span {
      font-weight: 700;
      color: $color-darkBlue;
    }

    [data-state="active"] & {
      z-index: 10;
      position: fixed;
      top: 20px;
      left: 20px;
      right: auto;
      opacity: 1;
      animation: slide-from-right 250ms 150ms both;

      .icon { display: none; }
      span { font-size: .85rem; }
    }
  }
  @media ($mq-desktop) {
    display: none;
  }
}


// ---------------------
// Close Btn
// ---------------------

.Nav-close {
  z-index: 10;
  position: fixed;
  top: 20px;
  right: 20px;
  opacity: 0;
  padding: 10px;
  color: $color-white;

  span {
    @include visually-hidden;
  }

  [data-state="active"] & {
    opacity: 1;
    animation: slide-from-right 250ms 150ms both;
  }

  .icon--cross {
    width: 20px;
    height: 20px;
  }

  [data-state="inactive"] & { display: none; }

  @media ($mq-desktop) {
    display: none;
  }
}


// ---------------------
// Panel
// ---------------------

.Nav-panel {
  @media ($mq-upto-tablet) {
    z-index: $zindex-nav;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    background-color: $color-darkBlue;
    transform: translateX(100%);
    transition: transform 250ms ease-out;

    [data-state="active"] & {
      transform: translateX(0);
    }
  }
}


// ---------------------
// List
// ---------------------

.Nav-list {
  @media ($mq-upto-tablet) {
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 30px;

    .Nav[data-state="active"] & {
      animation: slide-from-right 250ms 200ms both;
    }
  }
  @media ($mq-desktop) {
    display: flex;
  }
}


// ---------------------
// Item
// ---------------------

.Nav-item  {
  line-height: 1.2;
   a {
     display: block;
     font-family: $font-title;
     font-size: .8rem;
     font-weight: 700;
     text-transform: uppercase;
     color: $color-darkBlue;
     text-align: center;
  }
  // Circles
  .icon {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    z-index: 2;
    box-sizing: content-box;
    border-radius: 50%;
    color: #fff;
  }

  @media ($mq-upto-tablet) {
    a {
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .icon {
      width: 60px;
      height: 60px;
      border: 1px solid $color-white;
      margin-left: -10px;
      margin-right: 10px;
    }
    &--active span {
      text-decoration: underline;
    }
  }

  @media ($mq-desktop) {
    &--rwd {
      display: none !important;
    }
    a {
      padding: 10px 20px;
      font-size: .65rem;
    }
    .icon {
      margin-bottom: 8px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      color: $color-darkBlue;
      background-color: $color-menuGray;

      .Header-center & {
        color: white;
        background-color: $color-darkBlue;
      }
    }
    a span {
      position: relative;
      display: block;

      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0;
        height: 3px;
        right: 100%;
        background-color: $color-darkBlue;
      }
    }
    &--active a span:after {
      right: 0;
    }
    a:hover span::after,
    a:focus span::after {
      right: 0;
      animation: anim-grow-line 450ms ease both;
    }

    .Header[data-sticky="true"] & {
      a {
        padding-bottom: 12px;
      }
      .icon {
        width: 52px;
        height: 52px;
        margin-bottom: 4px;
      }
      a span::after {
        bottom: -5px;
        height: 2px;
      }
    }
  }
}


// ---------------------
// Social
// ---------------------

.Nav-social {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  opacity: 0;
  text-align: center;

  @media ($mq-upto-tablet) {
    [data-state="active"] & {
      opacity: 1;
      animation: slide-from-right 250ms 250ms both;
    }
  }

  @media ($mq-desktop) {
    display: none;
  }
}

.Nav-socialText {
  margin-bottom: 15px;
  font-size: .8rem;
  font-weight: 600;

  @media ($mq-upto-tablet) {
    display: none;
  }
}

.Nav-socialList {
  display: flex;
  justify-content: center;
  align-items: center;

  & > * { flex: 0 1 auto; }
}

.Nav-itemSocial {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-left: 6px;
  margin-right: 6px;
  line-height: 42px;
  border-radius: 50%;
  background-color: #fff;

  .icon {
    width: 19px;
    height: 19px;
    color: $color-darkBlue;
  }
}


// ---------------------
// Search
// ---------------------

.Nav-search {
  padding-left: 30px;
  padding-right: 30px;
}


// ---------------------
// Text
// ---------------------

.Nav-text {
  vertical-align: middle;
  display: inline-block;
  font-family: $font-title;
  font-size: .65rem;
  font-weight: 700;
  color: $color-white;
  text-transform: uppercase;

  @media ($mq-upto-tablet) {
    min-width: 5rem;
    text-align: left;
  }

  @media ($mq-desktop) {
    display: block;
    color: $color-darkBlue;
  }
}
