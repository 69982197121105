/*! Focus */

// ---------------------
// Main
// ---------------------

.Focus {
  padding: 50px 45px 60px;
  background-color: $color-pink;

  @media($mq-upto-tablet) {
    display: none;
  }
}

.Focus--yellow{
  @extend .Focus;
  background-color: $color-yellow;
}
.Focus--pink{
  @extend .Focus;
  background-color: $color-pink;
}
.Focus--green{
  @extend .Focus;
  background-color: $color-green;
}
.Focus--blue{
  @extend .Focus;
  background-color: $color-lightBlue;
}


// ---------------------
// Title
// ---------------------

.Focus-title {
  @extend .square, .square--center, .square--black;
  margin-bottom: 30px;
  font-family: $font-title;
  font-size: .8rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}


// ---------------------
// Focus
// ---------------------

.Focus-focus {
  margin-bottom: 20px;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.4;
}


// ---------------------
// Text
// ---------------------

.Focus-desc {
  margin-bottom:20px;
  font-size: 1rem;
  line-height: 1.5;
}


// ---------------------
// Source
// ---------------------

.Focus-source {
  font-size: .7rem;
  font-style: italic;
}