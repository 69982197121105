/*! Canvas */
// Background styles for large containers

.Canvas-1 {
  padding-top: 50px;
  padding-bottom: 10px; // Secure inside blocs margin
  background-color: $color-lighterGray;

  @media ($mq-from-tablet) {
    padding-top: 90px;
    background-image: url('../images/canvas.png');
  }
}