/*! Module Ma Radio FM */

#maradiofm {

  .bandeau_haut,
  .bandeau_haut02 {
    background: url('../images/bandeau_CSA_radio.png') no-repeat top center;
    padding-top: 180px;
  }
  .bandeau_haut03 {
    background: url('../images/bandeau_CSA_radio_detail.png') no-repeat top center;
    padding-top: 160px;
  }

  .zone_recherche {

    & > div {
      @media($mq-desktop) {
        width: 45%;
        margin-left: 40px;
        float: left;
      }
    }
  }

  .bandeau_grand_texte {
    clear: both;
  }

  .scroll_perso {
    display: block;

    @media($mq-desktop) {
      width: 780px;
      margin: 0;
    }
  }

  a.retour {
    @extend .link--back;
  }

  table a {
    color: $color-darkBlue !important;
  }

  .bouton_fiche_actuel {
    @extend .square;
    font-size: .8rem;
    font-weight: 700;
    margin: 30px 0;
    font-family: $font-title;
  }

  .bouton_fiche {
    display: none;
  }

  @media($mq-upto-tablet) {
    form[name="formStation"],
    form[name="formCommune"] {
      .champ_bouton {
        text-align: center;
      }
    }
  }
  

}