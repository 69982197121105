/*! Tooltip */

.tooltip {
  span {
    display: none;
  }

  @media($mq-desktop) {
    position: relative;
    display: inline;
    font-weight: 700;
    text-decoration: none;
    cursor: help;
    line-height: 1rem;

    /* Tooltip text */
    span {
        visibility: hidden;
        display: block;

        font-size: 0.65rem;
        font-weight: normal;
        position: absolute;
        left: -3px;
        transform: translateY(calc( -100% - 1.3rem ));
        min-height: 70px;
        min-width: 350px;
        padding: 20px;
        border-left: 3px solid $color-green;
        color: $color-black;
        background-color: $color-white;
        z-index: 2;
    }

    &:hover span {
      visibility: visible;
    }
  }
}
