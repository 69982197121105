/*! WYSIWYG */

.Wysiwyg {
  clear: both;
  margin-top: 40px;
  margin-bottom: 60px;

  @media ($mq-from-tablet) {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  // Prevent issues with floats before the Wysiwyg content or in it
  &::after {
    content: "";
    display: block;
    clear: both;
  }

  & > *:first-child { margin-top: 0; }
  & > *:last-child { margin-bottom: 0; }


  // ----------------------
  // Titles
  // ----------------------

  h2, h3, h4, h5 { font-family: $font-title; }

  h2 {
    @extend .square, .square--center, .square--green;
    border-top: 1px solid $color-gray;
    padding-top: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: $font-title;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    clear: both;
  }

  h3, h4, h5 {
    margin-top: 2em;
    margin-bottom: 1.5em;
  }
  h3 { font-size: 1rem; }
  h4 { font-size: .8rem; }
  h5 {
    font-size: .7rem;
    font-weight: 700;
    text-transform: uppercase;
    color: $color-darkBlue;
  }

  @media ($mq-from-tablet) {
    h2 {
      padding-top: 50px;
      margin-top: 50px;
      margin-bottom: 40px;
      font-size: 1.5rem;
    }
    h3 { font-size: 1.2rem; }
    h4 { font-size: 1rem; }
    h5 { font-size: .8rem; }
  }


  // ----------------------
  // Paragraphes
  // ----------------------

  p {
    margin-top: 20px;
    margin-bottom: 20px;

    @media ($mq-from-tablet) {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }


  // ----------------------
  // Lists
  // ----------------------

  ol,
  ul {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  li {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media ($mq-from-tablet) {
    ol,
    ul {
      margin-top: 25px;
      margin-bottom: 25px;
    }
    li {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  // Remove list margins for nested lists
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0;
    margin-bottom: 0;
  }

  ul > li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-darkBlue;
    }
  }

  ol {
    // roughly aligns the number to the left edge
    margin-left: 20px;
    list-style-position: outside;
  }
  ol > li {
    // creates a space between the number and the text
    padding-left: 10px;
  }


  // ----------------------
  // Links
  // ----------------------

  a {
    padding-bottom: 2px;
    font-weight: 700;
    color: $color-black;
    box-shadow: inset 0 -1px 0 currentColor;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  // Link around an image
  // Hover style defined in CSAREFONT-461
  .attribute-image a {
    box-shadow: none;
    &:hover,
    &:focus {
      box-shadow: inset 0 -1px 0 currentColor;
    }

    img {
      vertical-align: middle;
    }
  }

  // Link to download a document
  a[title*="ko"],
  a[title*="mo"],
  a[title*="Ko"],
  a[title*="Mo"] {
    position: relative;
    display: block;
    width: calc(100% + 15px); // 1 - Styles for hover effect
    padding: 15px 15px 15px 65px; // 1
    margin: -15px; // 1
    font-size: .65rem;
    font-weight: 500;
    line-height: 1.4;
    background-image: none;
    transition: background-color 150ms ease-in-out;

    &:hover, &:focus { background-color: lighten($color-lightBlue, 20%); }

    .icon--paperclip {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translate(-50%, -50%);
    }

    span {
      display: block;
      font-style: italic;
    }
  }

  .Press-item,
  .Press-more {
    font-weight: 600;
    background-image: none;
  }


  // ----------------------
  // Abbreviations
  // ----------------------

  abbr {
    @media ($mq-desktop) {
      position: relative;
      display: inline-block;
      font-weight: 700;
      text-decoration: none;

      &:before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $color-black;
      }

      &:hover {
        color: $color-green;

        &:before { content: none; }
        &:after {
          content: attr(title);
          position: absolute;
          left: -3px;
          transform: translateY(-100%);
          height: 70px;
          min-width: 200px;
          padding: 20px;
          border-left: 3px solid $color-green;
          white-space: nowrap;
          color: $color-black;
          background-color: #fff;
        }
      }
    }
  }


  // ----------------------
  // Blockquote
  // ----------------------

  blockquote {
    margin: 20px;
    font-family: $font-title;
    font-weight: 700;
    color: $color-darkBlue;

    span {
      display: block;
      margin-top: 20px;
      font-size: .8rem;
      font-weight: 500;
      text-align: right;
    }

    @media ($mq-from-tablet) {
      float: left;
      max-width: 50%;
      min-width: 320px;
      margin: 40px;
      font-size: 1.2rem;

      span { margin-top: 40px; }
    }
  }


  // ----------------------
  // Citations
  // ----------------------

  cite {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: .7rem;

    span { font-style: normal; }
  }


  // ----------------------
  // Images
  // ----------------------

  img {
    vertical-align: middle;

    &.left, &.right {
      padding-bottom: 10px;
    }
    @media ($mq-from-tablet) {
      &.left {
        float: left;
        padding-right: 30px;
      }
      &.right {
        float: right;
        padding-left: 30px;
      }
    }
  }

  @media ($mq-phone) {
    img {
      display: block;
    }
    table.center img {
      margin: 0 auto;
    }
  }


  // ----------------------
  // Files
  // ----------------------

  .embed-file {
    .icon {
      width: 2rem;
      height: 2rem;
      float: left;
      margin: 0 20px 0 10px;
    }
  }

  .ezbinaryfile-field {
    line-height: .9rem;
    font-size: .85rem;
    text-decoration: none;
    background: none;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      display: block;
      font-style: italic;
    }
  }


  // ----------------------
  // HR - clearfix
  // ----------------------

  hr {
    border: none;
    clear: both;
  }


  // ----------------------
  // Align
  // ----------------------

  @media ($mq-from-tablet) {
    .object-left {
      float: left;
      margin-right: 30px;
    }

    .object-right {
      float: right;
      margin-left: 30px;
    }

    .object-center {
      text-align: center;
    }
  }


  // ----------------------
  // Mise en avant
  // ----------------------

  .text_intro {
    font-style: italic;
  }

  .text_chapeau {
    font-size: 0.9rem;
    font-weight: 700;
    color: $color-darkBlue;
  }

  .text_mea {
    padding: 25px;
    background-color: $color-lightBlue;

    @media ($mq-from-tablet) {
      padding: 45px;
    }

    p {
      margin: 0 0 10px 0;
    }

    *:last-child{
      margin: 0;
    }
  }
}

// Variant without margins, used in the FAQ for example
.Wysiwyg--margin0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
