/*! Follow */


// ---------------------
// Main
// ---------------------

.Follow {
  border-top: 1px solid rgba(47,47,47,.2);

  @media($mq-from-tablet) {
    border-bottom: 1px solid rgba(47,47,47,.2);
  }
}

.Follow-container {
  @media($mq-from-tablet) {
    position: relative;
    display: flex;
    align-items: center;
    height: 250px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 1px;
      background-color: rgba(47,47,47,.2);
    }
  }
}


// ---------------------
// Half
// ---------------------

.Follow-half {
  @media($mq-phone) {
    padding-top: 35px;
    padding-bottom: 35px;

    &:last-child {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 0;
      text-align: center;
      color: #fff;
      background-color: $color-black;
    }
  }
  @media($mq-from-tablet) {
    flex: 0 1 auto;
    width: 50%;

    &:first-child { padding-right: 20px; }
    &:last-child {
      margin-top: 45px; // Simulate the baseline alignment with the newsletter form
      padding-left: 20px;
      text-align: right;
    }
  }
}


// ---------------------
// List of rsn
// ---------------------

.Follow-title {
  margin-bottom: 8px;
  font-weight: 600;

  @media($mq-phone) {
    margin-bottom: 5px;
  }
  @media($mq-from-tablet) {
    vertical-align: middle;
    display: inline-block;
  }
}

.Follow-subList {
  & > li {
    vertical-align: middle;
    display: inline-block;
  }
  & > li a {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
    background-color: #fff;
  }

  .icon {
    color: $color-black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .icon--facebook {
    vertical-align: -4px;
    height: 19px;
    width: 16px;
    top: 51%;
    left: 49%;
  }
  .icon--linkedin {
    left: 52%;
  }
  .icon--youtube {
    vertical-align: -4px;
    height: 19px;
    width: 18px;
  }
  .icon--twitter {
    vertical-align: -2px;
    height: 15px;
    width: 18px;
    top: 52%;
    left: 52%;
  }
  .icon--pinterest {
    vertical-align: -3px;
    height: 17px;
    width: 13px;
  }

  @media($mq-from-tablet) {
    vertical-align: middle;
    display: inline-block;
    margin-left: 30px;

    & > li a { background-color: $color-black; }
    .icon { color: #fff; }
  }
}