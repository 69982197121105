/*! Form */


#mot-cle-commune,
#mot-cle-radio,
.champ_recherche {
  margin: 20px 0;
  width: 100%;
}

input[type='text'],
input[type='email'],
select,
.ui-selectmenu-button.ui-button,
textarea {
  max-width: 100%;
  font-weight: 600;
  font-family: $font-title;
  padding: 12px 20px 15px 20px;
  border: 1px solid $color-black;
}

.ui-selectmenu-button.ui-button {
  border-radius: 0;
  width: 100%;
  background: $color-white;

  @media ($mq-desktop) {
    min-width: 230px;
  }
}

// Change custom sort arrow
.ui-button .ui-icon { display: none; }
.ui-selectmenu-button.ui-button {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    width: 0;
    height: 0;
    margin-top: -5px;
    border-style: solid;
    border-width: 9px 5px 0 5px;
    border-color: #000000 transparent transparent transparent;
  }
}

#form_annee .ui-selectmenu-button.ui-button {
  width: auto;
  min-width: 0;
}

.ui-selectmenu-menu {
  border: 1px solid $color-black;
  margin-top: -50px;
  line-height: 1.2;

  .ui-widget-content {
    padding: 6px 0;
  }

  .ui-menu-item-wrapper {
    padding: 8px 20px;

    &.ui-state-active {
      font-weight: 600;
      color: $color-black;
      border-color: transparent;
      background: $color-white url(../images/check2.svg) no-repeat calc( 100% - 15px ) center;
    }
  }
}

input[type='email']:not(#newsemail){
  width: 100%;
}

.form-input {
  margin-bottom: 16px;
}

.label_formtitre {
  display: block;
  clear: both;
  margin-top: 20px;
}

.label_form,
.label_formheure {
  width: 350px;
  max-width: 100%;
  display: inline-block;
  text-align: left;
  margin: 0 20px 0 0;
  padding: 6px 5px 0 0;
  line-height: 1.2em;
  clear: both;
  vertical-align: middle;

  &.valignTop {
    vertical-align: top;
    margin-top: 20px;
  }

  @media ($mq-desktop) {
    text-align: right;
  }
}

.field_form,
.field_formoption,
.field_formoption_large,
.field_formheure {
  width: 550px;
  max-width: 100%;
  display: inline-block;
  text-align: left;
  margin-top: 20px;
}

.field_formheure .element {
  width: 160px;
  display: inline-block;
}

.field_formoption label {
  display: block;
}

.form_day,
.form_month,
.form_year {
  width: 75px !important;
  display: inline-block;
}

.form_year {
  width: 90px !important;
}

.field_formheure {
  input[type='text'] {
    width: 75px !important;
    display: inline-block;
    margin-right: 15px;
  }

  label {
    display: inline-block;
  }
}


.container label {
  position: relative;
  margin-bottom: 5px;
  padding-left: 28px;
  cursor: pointer;
  display: block;
}

/* Hide the browser's default radio button */
.container input[type='radio'],
.Filters-filter input[type='radio'] {
  position: absolute;
  opacity: 0;
}

/* Create a custom radio button */
.container .checkmark,
.Filters-filter .checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $color-white;
  border: 1px solid $color-black;
}

.container input[type='radio'] ~ .checkmark,
.Filters-filter input[type='radio'] + label > .checkmark {
  border-radius: 50%;
}

/* On mouse-over, add a background color */
.container label:hover input[type='radio'] ~ .checkmark,
.Filters-filter label:hover input[type='radio'] + label > .checkmark {
  border: 5px solid $color-darkBlue;
}

/* When the radio/checkbox is checked, add a background */
.container input[type='radio']:checked ~ .checkmark,
.Filters-filter input[type='radio']:checked + label > .checkmark {
  border: 5px solid $color-darkBlue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.container:after,
.Filters-filter:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input[type='radio']:checked ~ .checkmark:after,
.Filters-filter input[type='radio']:checked + label > .checkmark:after {
  display: block;
}

/* Style the radio indicator */
.container input[type='radio'] ~ .checkmark:after,
.Filters-filter input[type='radio'] + label > .checkmark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: $color-white;
}

/* Style the checkbox checkmark/indicator */
.container input[type='checkbox'] ~ .checkmark:after,
.Filters-filter input[type='checkbox'] + label > .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $color-white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.field_collection,
.field_formoption,
.field_formoption_large {
  @extend .container;
}

.smad-form fieldset {
  margin-bottom: 30px;

  legend {
    @extend .square;
    margin-bottom: 10px;
  }
}


.btn-envoi {
  margin: 20px 0;

  @media($mq-desktop) {
    margin-left: 372px;
  }
}


.g-recaptcha {
  @media($mq-desktop) {
    margin-left: 370px;
  }

  & > div {
    width: 100%;
  }
}


/* Autocomplete */

.ui-autocomplete {
  border: 1px solid $color-white;
  background: $color-darkBlue;
  color: $color-white;
  padding: 15px;
}

/* Error message */
ul.error {
  margin: 50px 0;
  padding: 30px 0;
  color: red;
  border: 1px solid $color-pink;

  @media($mq-from-tablet) {
    padding: 30px 50px;
  }

  span {
    font-weight: 700;
    font-size: .9rem;
  }

  & > li:before {
    display: none;
  }

  ul li:before {
    background-color: red !important; /* Override the Module style, wich seem to be legacy */
  }
}
