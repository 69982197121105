/*! Results */

.Results {
  @media($mq-desktop) {
    position: relative;
    padding-bottom: 40px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -40px;
      bottom: 0;
      width: 1px;
      background-color: $color-gray;
    }
  }
}
