/*! Footer */


// ---------------------
// Main
// ---------------------

.Footer {
  color: #fff;
  background-color: $color-black;
}

.Footer-inner {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media($mq-from-tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
  }
}

.Footer-wrap {
  @media($mq-from-tablet) {
    & > * {
      vertical-align: middle;
      display: inline-block;
    }
  }
}


// ---------------------
// Copyright
// ---------------------

.Footer-cr {
  @media($mq-phone) {
    display: none;
  }
}


// ---------------------
// Navigation
// ---------------------

.Footer-nav {
  a { color: #fff; }

  @media($mq-phone) {
    padding-top: 30px;
    padding-bottom: 30px;

    a {
      display: block;
      line-height: 38px;
      text-align: center;
    }
  }

  @media($mq-from-tablet) {
    font-size: 0;

    a {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      padding: 15px 0 15px 15px;
      font-size: .7rem;
      font-weight: 600;

      &:after {
        content: '';
        vertical-align: baseline;
        display: inline-block;
        height: 8px;
        width: 1px;
        margin-left: 15px;
        background-color: #fff;
      }
      &:last-of-type:after { content: none; }

      &:before {
        content: "";
        position: absolute;
        bottom: 10px;
        left: 0;
        height: 1px;
        background-color: #fff;
      }

      &:hover:before, &:focus:before {
        right: 0;
        animation: anim-grow-line 450ms ease;
      }
    }
  }
}


// ---------------------
// Langs
// ---------------------

.Footer-langs {
  position: relative;
  margin-left: 55px;

  .icon {
    width: 25px;
    height: 17px;
  }

  @media($mq-phone) {
    display: none;
  }
}

.Footer-langsBtn {
  padding-left: 10px;
  padding-right: 30px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #ffffff transparent transparent transparent;
    margin-top: -4px;

    [data-state="active"] & { transform: rotate(180deg); }
  }

  &:hover, &:focus { opacity: .6; }
}

.Footer-langsList {
  position: absolute;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: max-height 250ms ease-in-out;

  a {
    display: block;
    padding: 5px 10px;
    font-size: 0;

    &:hover, &:focus { opacity: .6; }
  }

  [data-state="active"] & {
    max-height: 100px;
  }
}